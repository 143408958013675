/** override sidea */

$white: #fff;
$default-primary-base-color:  #c6a33b;
$default-primary-dark-color: #ccac4f;
$default-primary-light-color: #ae8f34;
$default-primary-extralight-color: #F5FFEF;
$default-secondary-base-color: #0511F2;
$default-secondary-dark-color: #0511F2;
$default-secondary-light-color: #0511F2;
$default-neutral-base-color: #222222;
$default-neutral-dark-color: #3C3C3B;
$default-neutral-light-color: #444444;
$default-neutral-extralight-color: #F0F0F0;
$default-neutral-thin-color: #CECECE;
$default-button-disabled: #C1C1C1;
$black: #0D0D0D;
$font-muli: 'Montserrat', sans-serif;
$font-base: 'Roboto', sans-serif;
$font-body-size: 1.5rem;
$font-standard-size: 62.5%;
