/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

:root {
    --facebook-color: #3b5998;
    --google-color: #74d167;
    --amazon-color: #e99a40;
    --twitter-color: #62bfe4;
    --linkedin-color: #626fe4;
    --yahoo-color: #b94747;
}

.SocialLoginButton {
    .Button {
        width: fit-content;
        min-width: unset;
        height: 50px;
        padding-inline: 25px;
        background-color: transparent !important;
        border: none;
        box-shadow: none;

        &:not([disabled]) {
            &:hover{
                background-color: #fff;
                border-color: var(--button-hover-border);
                color: #fff;
            }
        }
    }

    &_type {
        &_facebook {
            background-image: var(--facebook-icon);
            background-repeat: no-repeat;

            span {
                display: none;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &_google {
            background-image: var(--google-icon);
            background-repeat: no-repeat;

            span {
                display: none;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &_amazon {
            background-color: var(--amazon-color);
            border-color: var(--amazon-color);
        }

        &_twitter {
            background-image: var(--twitter-icon);
            background-repeat: no-repeat;

            span {
                display: none;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &_linkedin {
            background-image: var(--linkedin-icon);
            background-repeat: no-repeat;

            span {
                display: none;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &_yahoo {
            background-color: var(--yahoo-color);
            border-color: var(--yahoo-color);
        }
    }
}
