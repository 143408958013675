/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.StoreDetails {
    @include mobile {
        margin-block-end: 10px;
    }

    &-Container {
        max-width: 100vw;
        padding-block-end: 80px;
        border-block-end: 1px solid #DEDEDE;

        @include mobile {
            padding-block-end: 40px;
        }
    }

    .ContentWrapper {
        max-width: var(--content-wrapper-large);
        margin-inline: auto;
        
        @include mobile {
            max-width: 100vw;
            margin-block-start: 15px;
            padding-inline: 18px;
        }
    }

    &-SliderContainer {
        margin-block-start: 80px;

        @include mobile {
            margin-block-start: 20px;
            padding-block-end: 70px;
        }

        h3 {
            font-size: 4.2rem;
            font-weight: bold;
            text-align: center;
            color: #3A3939;
            margin-block-end: 40px;
            margin-block-start: 0;

            @include mobile {
                font-size: 3rem;
                margin-block-end: 25px;
                text-align: start;
            }
        }
    }

    &-SliderLink {
        font-weight: 600;
        font-size: 1.6rem;
        font-family: $font-muli;
        text-align: end;
        text-decoration: underline;
        color: #3A3939;
        margin-block-end: 40px;

        @include mobile {
            position: absolute;
            inset-block-end: 0;
            inset-inline: 0;
            z-index: 10;
            margin-block-end: 0;
            text-align: center !important;

            a {
                display: inline-block;
                text-align: center;
                text-decoration: none;
                width: 198px;
                height: 40px;
                line-height: 39px;
                background-color: #3A3939;
                color: #fff;
                border-radius: 20px;
                box-shadow: 8px 9px #DEDEDE;
            }
        }
    }

    &-Map {
        margin-block-end: 80px;

        @include mobile {
            margin-block-end: 30px;
            width: 100vw;
            margin-inline-start: -18px;
        }

        .leaflet-container {
            max-height: 500px;

            @include narrow-desktop {
                max-height: 400px;
            }

            @include ultra-narrow-desktop {
                max-height: 400px;
            }

            @include tablet {
                max-height: 400px;
            }

            @include mobile {
                max-height: 380px;
                border: none;
            }
        }
    }

    &-DetailsContainer {
        .StoreCard-Title {
            margin-block-start: 0;
            margin-block-end: 40px;
            text-transform: none;
            font-size: 4.2rem;
            font-weight: bold;
            font-family: $font-muli;

            @include narrow-desktop {
                font-size: 3.2rem;
            }
    
            @include ultra-narrow-desktop {
                font-size: 3.2rem;
            }
    
            @include tablet {
                font-size: 2.8rem;
            }

            @include mobile {
                font-size: 2.6rem;
                text-align: center;
                margin-block-end: 15px;
            }
        }
    }

    &-Details {
        display: flex;
        width: 100%;
        grid-gap: 95px;

        @include mobile {
            flex-direction: column;
            grid-gap: 15px;
        }

        .StoreCard-StoreImage {
            max-width: 100%;

            @include ultra-narrow-desktop {
                max-width: 400px;
            }

            @include tablet {
                max-width: 380px;
            }
        }

        h4 {
            margin: 0;
            margin-block-end: 30px;
            font-size: 3rem;
            font-weight: bold;

            @include narrow-desktop {
                font-size: 2.8rem;
                margin-block-end: 20px;
            }

            @include ultra-narrow-desktop {
                font-size: 2.2rem;
                margin-block-end: 15px;
            }

            @include tablet {
                font-size: 2rem;
                margin-block-end: 15px;
            }

            @include mobile {
                font-size: 2rem;
                margin-block-end: 6px;
            }
        }

        address {
            p, a {
                font-size: 2.4rem !important;
                font-weight: normal;
                font-family: $font-muli !important;

                @include narrow-desktop {
                    font-size: 2rem !important;
                }

                @include ultra-narrow-desktop {
                    font-size: 1.8rem !important;
                }

                @include tablet {
                    font-size: 1.8rem !important;
                }

                @include mobile {
                    font-size: 1.6rem !important;
                }
            }
        }

        .StoreCard-Field {
            p {
                font-size: 2.4rem !important;
                font-family: $font-muli !important;

                @include narrow-desktop {
                    font-size: 2rem !important;
                }

                @include ultra-narrow-desktop {
                    font-size: 1.8rem !important;
                }

                @include tablet {
                    font-size: 1.8rem !important;
                }

                @include mobile {
                    font-size: 1.6rem !important;
                    line-height: 1.8;
                }
            }
        }
    }
}
