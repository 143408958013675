/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends and override sidea */

.Footer {
    --footer-background-color: #{$black};

    $column-count: 4;

    color: $white;
    background-color: var(--footer-background-color);

    @include mobile {
        margin-block-end: 0;
    }

    .Newsletter {
        &-NewsletterBlockWrapper {
            background-color: #ffffff;
            padding-block: 95px;

            @include ultra-narrow-desktop {
                padding-block: 65px;
            }

            @include tablet {
                padding-block: 65px;
            }

            @include narrow-tablet {
                padding-block: 50px;
            }

            @include mobile {
                padding-block: 37px;
            }
        }

        &-NewsletterContent {
            margin-block: 0;
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
 
        .Prefooter {
            &-BlockWrapper {
                border-bottom: 1px solid #DEDEDE;
                padding-block-end: 40px;

                @include mobile {
                    padding-block-end: 0;
                    border-bottom: none;
                }

                .ContentWrapper {
                    display: flex;
                    align-items: flex-end;
                    padding-inline: 8%;

                    @include ultra-narrow-desktop {
                        padding-inline: 3%;
                    }
            
                    @include tablet {
                        padding-inline: 3%;
                    }
                    
                    @include mobile {
                        flex-direction: column;
                        row-gap: 10px;
                        padding-inline: 0;
                    }
                }
            }
        }
    }

    .Image.Logo {
        overflow: visible;
        height: auto;
        padding-inline-end: 7.5%;
        width: 25.4%;
        margin-block-end: 0;
        margin-block-start: -14px;
        background-color: transparent;

        @include ultra-narrow-desktop {
            padding-inline-end: 5%;
        }

        @include tablet {
            padding-inline-end: 5%;
        }

        @include narrow-tablet {
            padding-inline-end: 5%;
        }

        @include mobile {
            width: 115px;
            padding-inline-start: 0;
            padding-inline-end: 0;
            margin-block-end: 0;
            margin: 0 auto !important;
        }

        .Logo-Placeholder {
            position: relative;
        }

        img {
            position: relative;
        }

        .Image-Image::after {
            display: none;
        }
    }

    .ContentWrapper {
        max-width: var(--content-wrapper-large);
    }

    &-Columns {
        font-family: $font-muli;
        width: var(--content-wrapper-large);
        padding-inline: 0;
        align-items: flex-start;

        @include mobile {
            display: block;
            text-align: start;
            padding: 20px 18px;
            padding-inline: 0;
        }
    }

    &-Column {
        padding-inline-end: 0;
        width: 33%;

        @include mobile {
            width: 50%;
            display: inline-block;
        }

        &:not(:last-of-type) {
            padding-inline-end: 0;
        }

        &:nth-child(5) {
            @include mobile {
                width: 100%;
                padding-block-start: 35px;
                border-top: 1px solid #444444;
                
                .Footer-ColumnContent {
                    flex-direction: row;
                    flex-wrap: wrap;
                    
                    a {
                        flex: 1 1 40%;
                    }
                }
            }
        }

        &.InfoAddress {
            font-family: $font-base;
            margin-block-start: 100px;

            @include ultra-narrow-desktop {
                margin-block-start: 20px;
            }

            @include tablet {
                margin-block-start: 40px;
            }

            @include narrow-tablet {
                margin-block-start: 20px;
            }

            @include mobile {
                width: 100%;
                margin-block-start: 20px;
                display: flex;
                flex-direction: column-reverse;
            }

            p {
                font-size: 1.2rem;
                letter-spacing: 1px;
                line-height: 1.8;

                br {
                    &:nth-child(3) {
                        @include mobile {
                            display: none;
                        }
                    }
                }
            }

            .CmsBlock-Wrapper {
                @include tablet {
                    width: 200px;
                }

                @include mobile {
                    div {
                        div {
                            flex-direction: row !important;
                            justify-content: space-between !important;
                        }
                    }
                }
            }

            .Social-box {
                display: flex;
                align-items: flex-start;
                margin-block-end: 30px;

                @include mobile {
                    margin-block-start: 0;
                    margin-block-end: 0;
                    justify-content: flex-start;
                    padding-inline: 0;
                    padding-block-end: 35px;
                    border-block-end: 1px solid #444444;
                }

                div {
                    margin-inline-end: 12px;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 45px;
                        height: 45px;
                        border: 1px solid #fff;
                        border-radius: 50%;

                        @include ultra-narrow-desktop {
                            width: 33px;
                            height: 33px;
                        }

                        @include narrow-tablet {
                            width: 30px;
                            height: 30px;
                        }

                        &:hover {
                            border: 1px solid $default-primary-base-color;
                        }
                    }

                    img {
                        height: 17px;
                        width: auto;

                        @include ultra-narrow-desktop {
                            height: 13px;
                        }

                        @include narrow-tablet {
                            height: 13px;
                        }

                        &.Youtube {
                            height: 15px;
                            margin-block-start: 1px;

                            @include ultra-narrow-desktop {
                                height: 10px;
                            }
    
                            @include narrow-tablet {
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }

        .Menu {
            &-MainCategories {
                margin: 0;
            }

            &-ItemList {
                display: flex;
                flex-direction: column;
                gap: 5px;
                text-align: start;

                @include narrow-desktop {
                    gap: 3px;
                }
        
                @include ultra-narrow-desktop {
                    gap: 3px;
                }
        
                @include narrow-tablet {
                    gap: 3px;
                }
            }

            &-Item {
                margin-inline: 0;

                @include mobile {
                    border-bottom: none;
                }

                &:first-child {
                    margin-inline-start: 0;
                }

                &:not(:first-of-type) {
                    margin-inline-start: 0;
                }

                &.Promo {
                    .Menu-ItemCaption {
                        color: $default-primary-base-color;
                    }

                    &:hover {
                        .Menu-ItemCaption_type_main {
                            color: $default-primary-dark-color;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            &-ItemCaption_type_main {
                line-height: 22px;
                padding-block-start: 0;
                padding-block-end: 0;
                font-weight: 600;

                @include mobile {
                    font-size: 11px;
                    line-height: 1.1;
                    font-weight: 500;
                    white-space: normal;
                    padding-block: 0.35rem;
                }
            }

            &-Link_isHovered {
                .Menu-ItemCaption_type_main::after {
                    display: none;
                }
            }

            &-Additional {
                @include mobile {
                    display: none;
                }
            }
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        gap: 5px;

        @include narrow-desktop {
            gap: 3px;
        }

        @include ultra-narrow-desktop {
            gap: 3px;
        }

        @include narrow-tablet {
            gap: 3px;
        }

        @include mobile {
            gap: 3px;
        }
    }

    &-ColumnItem {
        border-radius: 0;
        color: $white;
        margin-block-end: 0;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 22px;
        white-space: nowrap;

        &:hover {
            color: var(--link-hover);
            text-decoration: none;
        }

        @include mobile {
            font-size: 11px;
            white-space: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }

    &-LastColumn {
        color: $white;
        width: 59%;

        @include mobile {
            width: 100%;
            padding-inline-start: 0;
        }

        .phone {
            color: $white;
            font-size: 1.4rem;
            letter-spacing: 1px;
            font-weight: 700;
            margin-block-start: 0;

            @include mobile {
                width: 50%;
                display: inline-block;
                margin-block-end: 0;
                font-size: 1.8rem;
                font-weight: 500;
            }

            &::before {
                content: var(--icon-phone-white);
                margin-inline-end: 8px;

                @include mobile {
                    vertical-align: top;
                }
            }            
        }

        .p14 {
            font-family: $font-base;
            font-size: 1.2rem;
            letter-spacing: 1px;
            max-width: 404px;

            br {
                @include ultra-narrow-desktop {
                    display: none;
                }

                @include narrow-tablet {
                    display: none;
                }

                @include mobile {
                    display: none;
                }
            }
        }

        .CmsBlock-Wrapper {
            div {
                div {
                    @include mobile {
                        display: block !important;  /* stylelint-disable-line declaration-no-important */
                    }

                    .p14 {
                        @include desktop-wide-1440 {
                            font-size: 1.2rem;
                        }
                
                        @include narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include ultra-narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include tablet {
                            font-size: 1rem;
                        }
                    }

                    .p12 {
                        @include mobile {
                            line-height: 1.5;
                        }

                        & br {
                            @include narrow-desktop {
                                display: none;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
            }

            .image-nofull, .image-nofull img {
                margin-block: 8px;
            }
        }

        .link-primary,
        a {
            color: $white;
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                color: $default-primary-base-color;
            }
        }

        .shopping-sicuro {
            &::after {
                content: var(--loghi-secure);
                display: block;
                margin-block-start: 3px;
            }
        }
    }

    &-ColumnTitle {
        color: $white;
        white-space: nowrap;
        font-weight: 800;
        font-size: 1.2rem;
        text-transform: uppercase;

        @include mobile {
            margin-block-end: 18px;
        }
    }

    &::after {
        display: block;
        content: var(--background-green);
        width: 100%;
        overflow: hidden;

        @include narrow-desktop {
            max-height: 200px;
        }

        @include ultra-narrow-desktop {
            max-height: 200px;
        }

        @include tablet {
            max-height: 160px;
        }

        @include mobile {
            display: none;
        }
    }

    &-CopyrightContentWrapper {
        background-color: transparent;
        color: $white;
        padding-block-end: 55px;

        @include mobile {
            text-align: start;
            padding-inline: 0;
            padding-block-end: 40px;
        }
    }

    &-CopyrightContent {
        --footer-text-size: 1rem;

        padding: 0;
        justify-content: flex-start;
        letter-spacing: 1px;
        font-size: var(--footer-text-size);

        .Footer-Copyright {
            color: $white;
            padding-inline: 0;
            font-size: var(--footer-text-size);
        }

        a {
            font-size: var(--footer-text-size);
            font-weight: 700;
            margin-inline-end: 10px;
        }

        .Footer-PrivacyLinks {
            text-transform: uppercase;
            margin-inline-start: 30px;

            a {
                &:first-child {
                    &::after {
                        content: "|";
                        font-size: 10px;
                        margin-inline-start: 10px;
                    }
                }
            }
        }
    }

    &.CheckoutPage {
        .Newsletter-NewsletterBlockWrapper {
            display: none;
        }

        .Footer-CopyrightContentWrapper {
            @include desktop-wide-1440 {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 60px;
                margin-block-start: 0px;
            }

            @include narrow-desktop {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 40px;
                margin-block-start: 0px;
            }

            @include tablet {
                padding-inline-start: 32px;
                max-width: 100%;
                padding-inline-end: 32px;
                padding-block-end: 30px;
                margin-block-start: 40px;
            }
        }
    }
}
