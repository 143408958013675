/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Avviso giacenza Sidea */

.StockNote {
    &-Alert {
        font-family: $font-base;
        font-size: 1.1rem;
        letter-spacing: 0.085rem;
        margin-block-end: 20px;
        color: $default-neutral-base-color;
        text-transform: none;
    
        svg {
            fill: $default-neutral-base-color;
            stroke: none;
            display: block;
            margin-block-end: 3px;
        }
    }
}
