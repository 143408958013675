/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.StoreFinder {
    margin-block-end: 100px;

    @include mobile {
        margin-block-end: 0; 
    }

    &-Wrapper {
        margin-inline: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 100vw;
        padding-block-end: 80px;
        border-block-end: 1px solid #DEDEDE;

        @include mobile {
            display: block;
            padding: 0;
        }
    }

    &-SelectList {
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        gap: 20px;
        margin-bottom: 50px;
        align-items: baseline;

        @include mobile {
            grid-gap: 0;
            gap: 0;
            margin-bottom: 30px;
        }
        
        h5 {
            font-size: 2.4rem;
            font-weight: normal;
            color: #3A3939;

            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }

        @include mobile {
            flex-direction: column;
        }
    }

    &-Select {
        width: 100%;
        max-width: 500px;

        @include desktop-wide-1600 {
            max-width: 460px;
        }

        @include desktop-wide-1440 {
            max-width: 400px;
        }

        @include ultra-narrow-desktop {
            max-width: 400px;
        }

        @include tablet {
            max-width: 400px;
        }
    }

    &-StoreContent {
        display: flex;
        grid-gap: 120px;

        @include desktop-wide-1600 {
            grid-gap: 100px;
        }

        @include desktop-wide-1440 {
            grid-gap: 80px;
        }

        @include ultra-narrow-desktop {
            grid-gap: 60px;
        }

        @include tablet {
            grid-gap: 50px;
        }

        @include mobile {
            flex-direction: column-reverse;
            grid-gap: 30px;
        }
    }

    &-StoreCards {
        display: flex;
        flex-direction: column;
        max-height: 680px;
        max-width: 641px;
        width: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar  {
            width: 7px;
            background-color: #DEDEDE;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #656565; 
            border-radius: 10px; 
        }

        @include desktop-wide-1600 {
            max-width: 570px;
            max-height: 660px;
        }

        @include desktop-wide-1440 {
            max-width: 510px;
            max-height: 650px;
        }

        @include narrow-desktop {
            max-width: 460px;
            max-height: 630px;
        }

        @include ultra-narrow-desktop {
            max-width: 455px;
            max-height: 580px;
        }

        @include tablet {
            max-width: 400px;
            max-height: 480px;
        }

        @include mobile {
            grid-template-columns: 1fr;
            max-width: 100%;
            max-height: fit-content;
            overflow-y: auto;
        }
    }

    &-Store {
        display: flex;
        margin-block-end: 40px;

        @include mobile {
            text-align: center;
        }
    }

    &-Map {
        max-width: 1020px;
        width: 100%;

        @include mobile {
            width: 100vw;
            margin-inline-start: -18px;
            height: 379px;
        }

        .leaflet-container {
            z-index: 0;
            border: var(--leaflet-map-border);
            max-height: 680px;
            height: 100%;
        }

        .leaflet-popup-content-wrapper {
            box-shadow: none;
            border-radius: 3px;
            width: 100%;
            min-width: 330px;
            min-height: 330px;
            height: fit-content;
        }

        .leaflet-popup-content {
            width: 100% !important;
            height: fit-content;
        }
    }

    .ContentWrapper {
        max-width: var(--content-wrapper-large);
        margin-inline: auto;
        
        @include mobile {
            max-width: calc(100% - 36px);
        }
    }
}
