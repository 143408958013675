/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';


/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.StoreCard {
    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);
        font-size: 1.2rem;
        color: #fff;
        font-weight: 600;
        text-align: center;
        padding-block-start: 7px;

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }

    &-Details {
        display: flex;
        grid-gap: 40px;
        cursor: pointer;

        @include desktop-wide-1440 {
            grid-gap: 30px;
            padding-inline-end: 15px;
        }

        @include ultra-narrow-desktop {
            grid-gap: 20px;
            padding-inline-end: 10px;
        }

        @include tablet {
            grid-gap: 20px;
            padding-inline-end: 10px;
        }

        @include mobile {
            grid-gap: 0;
            flex-direction: column;
            width: 100%;
        }
    }

    &-StoreImage {
        width: 100%;
        max-width: 194px;
        min-width: 194px;

        @include desktop-wide-1440 {
            max-width: 174px;
            min-width: 174px;
        }

        @include narrow-desktop {
            max-width: 174px;
            min-width: 174px;
        }

        @include ultra-narrow-desktop {
            max-width: 150px;
            min-width: 150px;
        }

        @include tablet {
            max-width: 120px;
            min-width: 120px;
        }

        @include mobile {
            max-width: 375px;
        }
    }

    &-Title {
        margin-block: 15px;
        font-size: 3rem;
        font-weight: bold;
        color: #3A3939;
        text-transform: uppercase;

        @include desktop-wide-1600 {
            font-size: 2.4rem;
        }

        @include desktop-wide-1440 {
            font-size: 2.2rem;
        }

        @include ultra-narrow-desktop {
            font-size: 2.1rem;
            margin-block: 5px;
        }

        @include tablet {
            font-size: 2rem;
            margin-block: 5px;
        }

        @include mobile {
            margin-block-end: 10px;
        }
    }

    &-Content {
        margin-block-end: 27px;

        @include tablet {
            margin-block-end: 15px;
        }

        @include mobile {
            margin-block-end: 19px;
        }

        p {
            margin: 0px;
            padding: 0px;
            font-size: 1.6rem;
            font-weight: normal;
            font-family: $font-base;
            color: #3A3939;

            @include tablet {
                font-size: 1.4rem;
            }
        }
    }

    &-ContentField {
        margin-block-end: 5px;
        
        a {
            font-size: 1.6rem !important;
            color: #3A3939 !important;
            font-family: $font-base !important;
            font-style: normal !important;
        } 
    }

    &-Navigation {
        margin-block-start: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include tablet {
            margin-block-start: 0;
        }
    }

    &-ButtonShop {
        border: 1px solid #3A3939;
        border-radius: 20px;
        font-size: 2.1rem;
        font-family: $font-muli;
        font-weight: normal;
        width: 237px;
        height: 50px;
        text-align: center;
        padding: 8px;

        &:hover {
            border: 1px solid var(--primary-base-color);
            color: var(--primary-base-color);
        }

        @include tablet {
            font-size: 1.8rem;
            border-radius: 20px;
            width: 237px;
            height: 40px;
            padding: 0;
            line-height: 36px;
        }

        @include mobile {
            margin: 0 auto;
            font-size: 1.6rem;
            border-radius: 20px;
            width: 195px;
            height: 40px;
            padding: 0;
            line-height: 36px;
        }
    }
}
