/** extend sidea */

h2 {
    &.title-click-type {
        font-size: 4.2rem;
        font-weight: bold;
        margin-block-start: 0;

        @include narrow-desktop {
            font-size: 3.3rem;
        }

        @include ultra-narrow-desktop {
            font-size: 3rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include mobile {
            font-size: 2.6rem;
            margin-block-end: 3px !important;
        }
    }
}

h3 {
    &.title-click-type {
        font-size: 4.2rem;
        font-weight: bold;

        @include narrow-desktop {
            font-size: 3.3rem;
        }

        @include ultra-narrow-desktop {
            font-size: 3rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include mobile {
            font-size: 2rem !important;
            margin-block-end: 15px !important;
            margin-block-start: 15px;
        }
    }
}

.background-second-column {
    background-image: var(--click-collect-image);
    margin-inline-start: 18px;

    @include mobile {
        background-image: var(--click-collect-image-mobile);
        margin-inline-start: 0;
        min-height: 300px;
        background-size: contain !important;
        background-position: top center !important;
    }
}

.background-second-column::before {
    content: var(--click-collect-buste);
    position: absolute;
    inset-inline-start: 34%;
    inset-block-start: 68%;

    @include desktop-wide-1600 {
        inset-inline-start: 30%;
        transform: scale(0.9);
    }

    @include desktop-wide-1440 {
        transform: scale(0.8);
        inset-inline-start: 29%;
        inset-block-start: 61%;
    }

    @include narrow-desktop {
        transform: scale(0.7);
        inset-inline-start: 26%;
        inset-block-start: 57%;
    }

    @include ultra-narrow-desktop {
        transform: scale(0.55);
        inset-inline-start: 18%;
        inset-block-start: 42%;
    }

    @include tablet {
        transform: scale(0.5);
        inset-inline-start: 16%;
        inset-block-start: 40%;
    }

    @include mobile {
        display: none;
        transform: scale(0.5);
        inset-inline-start: 8%;
        inset-block-start: 40%;
    }
}

.divider-cc {
    width: 100%;
    border-bottom: 1px solid #DEDEDE;
    display: flex;
    align-self: center;

    @include mobile {
        border-bottom: 1px solid #DEDEDE;
        width: 100vw;

        &.mb-2 {
            margin-block-end: 10px !important;
        }

        &.mb-5 {
            margin-block-end: 25px !important;
            margin-block-start: -5px;
        }
    }

    &.varFux {
        border-bottom: 5px solid #F4A2F6;
    }
}

.container-cc {
    width: 69%;
    margin: auto;

    .pagebuilder-column-group {
        justify-content: space-between;

        @include narrow-desktop {
            gap: 30px;
        }

        @include ultra-narrow-desktop {
            gap: 20px;
        }

        @include tablet {
            gap: 20px;
        }

        @include mobile {
            width: 100%;
        }
    }

    @include desktop-wide-1600 {
        width: 85%;
    }

    @include desktop-wide-1440 {
        width: 92%;
    }

    @include narrow-desktop {
        width: 97%;
    }

    @include ultra-narrow-desktop {
        width: 100%;
    }

    @include tablet {
        width: 100%;
    }

    @include mobile {
        width: 100%;

        &.mb-10 {
            margin-block-end: 0px !important;
        }

        &.mb-4 {
            margin-block-end: 30px !important;
        }
    }

    .p16 {
        @include desktop {
            width: 65%;
            margin: 0 auto;
        }

        @include desktop-wide-1440 {
            width: 75%;
        }

        @include narrow-desktop {
            width: 85%;
        }

        @include ultra-narrow-desktop {
            width: 90%;
            font-size: 1.4rem;
        }

        @include tablet {
            width: 90%;
            font-size: 1.4rem;
        }

        @include narrow-tablet {
            width: 100%;
            font-size: 1.4rem;
        }

        @include mobile {
            width: 100%;
            font-size: 1.2rem;
        }

        p {
            line-height: 1.55 !important;
            margin-block-end: 0;

            @include ultra-narrow-desktop {
                line-height: 1.35 !important;
                font-size: 1.2rem;
            }
    
            @include tablet {
                line-height: 1.35 !important;
                font-size: 1.2rem;
            }
        }
    }

    &.list-steps {
        &.mb-7 {
            @include ultra-narrow-desktop {
                margin-block-end: 40px !important;
            }

            @include tablet {
                margin-block-end: 40px !important;
            }
        }

        .p16 {
            @include desktop {
                width: 100%;
            }
        }

        .pagebuilder-column-group {
            @include narrow-desktop {
                gap: 30px;
            }
            
            @include ultra-narrow-desktop {
                gap: 20px;
            }
    
            @include tablet {
                gap: 20px;
            }

            .number-al {
                @include narrow-tablet {
                    width: 100% !important;
                    align-items: center;
                }

                .p16 {
                    @include narrow-tablet {
                        width: 100%;
                        max-width: 500px;
                    }

                    p {
                        @include narrow-tablet {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

.text-cc {
    width: 100%;
    max-width: 850px;
    margin: auto;

    &.p16 {
        @include mobile {
            margin-block-end: 30px;
        }

        p {
            line-height: 1.6 !important;
            margin-block-end: 0;
    
            @include ultra-narrow-desktop {
                line-height: 1.35 !important;
                font-size: 1.7rem;
            }
    
            @include tablet {
                line-height: 1.35 !important;
                font-size: 1.7rem;
            }
        }
    }
}

.step-column {
    width: 100%;
    max-width: 380px;
    border: 1px solid #DEDEDE;
    height: 380px;
    padding-block-end: 25px;

    @include ultra-narrow-desktop {
        height: 350px; 
    }

    @include tablet {
        height: 320px; 
    }

    @include mobile {
        height: 270px;
        padding-inline: 12px;
    }
}

.cc-pw {
    width: 100%;
    max-width: 300px;

    @include desktop-wide-1600 {
        width: 90% !important;
    }

    @include mobile {
        margin: 0 auto;
        margin-block-end: 40px;
    }

    &.p16 p {
        @include desktop-wide-1600 {
            font-size: 1.4rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.4rem;
        }

        @include tablet {
            font-size: 1.4rem;
        }
    }
}

.uomo-corsa {
    position: relative !important;
    display: flex;
    justify-content: center;
    width: 75%;
    margin-block-start: auto;

    @include mobile {
        width: 100%;
        position: absolute !important;
        inset-block-end: 15px;
        inset-inline-start: 0;
    }

    &.s1 {
        opacity: 0.25;
    }

    &.s2 {
        opacity: 0.5;
    }

    &.s3 {
        opacity: 1;
    }
}

