/** extend sidea */
/* stylelint-disable declaration-no-important */

:root {
    --font-p-mobile: 1.4rem;
    --line-height-p-mobile: 1.5;
    --font-weight-p-mobile: 400;
}

.CmsPage {
    @include mobile {
        margin-block-end: 0 !important;
    }

    .topbanner {
        padding-inline: var(--padding-content-wrapper);
        padding-block-end: 150px;
        padding-block-start: 30px;

        @include mobile {
            padding-inline: 0;
            padding-block-end: 0;
            padding-block-start: 0;
        }

        &::after {
            content: var(--arrow-down);
            margin: auto;
            inset-block-start: 40px;
            position: relative;

            @include mobile {
                display: none;
            }
        }

        .pagebuilder-column-group {
            position: absolute;
            height: 44%;
            max-height: 360px;
            inset-block-start: calc((56% / 2) - 33px);
            width: var(--content-wrapper-large);

            @include desktop-wide-1600 {
                inset-block-start: calc((49% / 2) - 33px);
            }

            @include mobile {
                position: relative;
                height: auto;
                max-height: 360px;
                inset-block-start: unset;
                width: 100%;
                flex-direction: column;
                padding-inline: 18px;
                padding-block-end: 45px;
                padding-block-start: 45px;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }
            }

            .column.first {
                max-width: 592px;
                justify-content: center !important;
                background-color: $default-neutral-base-color;
                padding-inline-start: 4.8%;

                @include mobile {
                    width: 100%;
                    max-width: 100%;
                    padding-inline-start: 0;
                }

                p {
                    font-family: $font-muli;
                    text-transform: uppercase;
                    font-size: 3rem;
                    line-height: 1;
                    font-weight: 800;
                    margin-block: 0;

                    @include mobile {
                        font-size: 2.1rem;
                        letter-spacing: 0.17rem;
                        margin-block-end: 16px;
                    }
                }
            }

            .column.second {
                padding-inline: 7.6%;
                justify-content: center !important;

                @include mobile {
                    width: 100% !important;
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }

                p {
                    font-family: $font-muli;
                    font-size: 3rem;
                    font-weight: normal;
                    line-height: 1;
                    margin: 0;
                    letter-spacing: 0.24rem;
                    max-width: 740px;

                    @include desktop-wide-1600 {
                        font-size: 2.4rem;
                    }

                    @include ultra-narrow-desktop {
                        font-size: 2.1rem;
                    }

                    @include tablet {
                        font-size: 2.1rem;
                    }

                    @include narrow-tablet {
                        font-size: 2.1rem;
                    }

                    @include mobile {
                        font-size: 1.4rem;
                        letter-spacing: 0.084rem;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    &#chi-siamo {
        .topbanner {
            .pagebuilder-column-group {
                @include mobile {
                    padding-block-start: 20px;
                    padding-block-end: 30px;
                    max-height: fit-content;
                }
            }

            .column.first {
                @include mobile {
                    background-color: transparent;
                }
            }

            .SliderWidget {
                &::before {
                    content: '“';
                    font-family: $font-muli;
                    font-size: 16rem;
                    color: $default-primary-base-color;
                    position: absolute;
                    inset-block-start: -18px;

                    @include ultra-narrow-desktop {
                        font-size: 14rem;
                    }

                    @include tablet {
                        font-size: 14rem;
                    }

                    @include narrow-tablet {
                        font-size: 14rem;
                    }

                    @include mobile {
                        inset-block-start: 70px;
                        inset-inline-start: -8px;
                    }
                }

                &-FigureImage {
                    padding-block-end: 32%;

                    @include ultra-narrow-desktop {
                        padding-block-end: 40%;
                    }
                    
                    @include tablet {
                        padding-block-end: 40%;
                    }

                    @include narrow-tablet {
                        padding-block-end: 40%;
                    }

                    @include mobile {
                        padding-block-end: 0;
                    }
                }

                &-Figcaption {
                    max-width: 100%;
                }

                p {
                    font-family: $font-muli;
                    font-size: 1.4rem;
                    letter-spacing: 0.084rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 1.8;
                    margin: 0;
                    min-width: unset;
                    margin-block-end: 24px;

                    @include mobile {
                        font-weight: 600;
                        font-size: 1.2rem;
                        line-height: 1.5;
                        letter-spacing: 0.072rem;
                        margin-block-end: 15px;
                    }

                    strong {
                        color: $default-primary-base-color;
                        font-weight: 800;

                        @include mobile {
                            font-weight: 600;
                        }
                    }

                    &:first-child {
                        margin-block-start: 40px;

                        @include mobile {
                            margin-block-start: 40px;
                        }
                    }
                }

                .slick-dots {
                    inset-block-end: 1px;
                    text-align: start;

                    @include mobile {
                        inset-block-end: 8px;
                    }

                    li {
                        button {
                            border: 1px solid #ffffff;
                        }

                        &.slick-active {
                            button {
                                background-color: #ffffff;
                            }
                        }
                    }
                }
            }
        }

        .CmsPage-Content {
            .pagebuilder-column {
                figure {
                    height: 100%;
                    overflow: hidden;
                }

                .col-6 {
                    width: 48%;
                    display: inline-block;
                    vertical-align: middle;

                    @include ultra-narrow-desktop {
                        display: block;
                        width: 100%;
                    }
    
                    @include tablet {
                        display: block;
                        width: 100%;
                    }
    
                    @include narrow-tablet {
                        display: block;
                        width: 100%;
                    }

                    @include mobile {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .section-one,
            .section-two {
                padding-block: 80px;

                @include mobile {
                    padding-block: 30px;
                }

                .pagebuilder-column-group {
                    @include mobile {
                        flex-direction: column;
                    }
                }

                .pagebuilder-column {
                    @include mobile {
                        width: 100% !important;
                    }
                    
                    &:first-child {
                        figure {
                            img {
                                width: auto;

                                @include mobile {
                                    width: 100%;

                                    &.pagebuilder-mobile-only {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-column-text {
                padding: 4.1% 7.5%;

                @include ultra-narrow-desktop {
                    padding: 4.1% 5%;
                }

                @include tablet {
                    padding: 4.1% 5%;
                }

                @include narrow-tablet {
                    padding: 4.1% 5%;
                }

                @include mobile {
                    padding-inline: 0;
                    background-color: transparent !important;
                }

                h2 {
                    width: 51%;
                    margin-block-start: 0;

                    @include ultra-narrow-desktop {
                        width: 100%;
                    }
    
                    @include tablet {
                        width: 100%;
                    }
    
                    @include narrow-tablet {
                        width: 100%;
                    }

                    @include mobile {
                        width: 205px;
                        letter-spacing: 0.24rem;
                        line-height: 1.1;
                        margin-block-start: 6px;
                        margin-block-end: 15px;
                    }
                }

                h3 {
                    line-height: 27px;
                    margin-block-end: 20px;
                    margin-block-start: 0;

                    @include mobile {
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 16px;
                    }
                }

                p {
                    font-size: 1.5rem;
                    line-height: 27px;

                    @include ultra-narrow-desktop {
                        line-height: 1.7;
                    }

                    @include tablet {
                        line-height: 1.7;
                    }

                    @include narrow-tablet {
                        line-height: 1.7;
                    }

                    @include mobile {
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }
                }
            }

            .section-two {
                padding-block-end: 168px;

                @include mobile {
                    padding: 0;
                    padding-block-end: 0;
                }

                .right-column-text {
                    display: block;
                    color: $black;

                    @include mobile {
                        padding-block-end: 50px;
                    }

                    h2 {
                        margin-block-end: 10px;

                        @include mobile {
                            margin-block-start: 12px;
                            width: 300px;
                        }

                        &.col-6 {
                            margin-block-end: 0;
                        }
                    }

                    h3 {
                        @include mobile {
                            font-weight: 700;
                        }
                    }

                    h2, h3, p {
                        @include mobile {
                            padding-inline: 18px;
                        }
                    }

                    .pagebuilder-button-primary {
                        margin-inline-start: 38px;
                        color: $default-primary-base-color;

                        @include ultra-narrow-desktop {
                            margin-block-start: 15px;
                            margin-inline-start: 0;
                        }
        
                        @include tablet {
                            margin-block-start: 15px;
                            margin-inline-start: 0;
                        }
        
                        @include narrow-tablet {
                            margin-block-start: 15px;
                            margin-inline-start: 0;
                        }

                        @include mobile {
                            margin-block-start: 18px;
                            margin-inline-start: 18px;
                        }
                    }
                }
            }

            .section-sedi {
                padding-block: 58px 150px;

                @include mobile {
                    padding-block: 20px;
                }

                .pagebuilder-column-group {
                    margin-block-start: 55px;
                    gap: 40px;

                    @include mobile {
                        margin-block-start: 0;
                        flex-wrap: wrap;
                        gap: 22px;
                    }

                    figure, img {
                        height: auto !important;
                    }

                    h2, h3 {
                        margin-block-end: 0;

                        @include mobile {
                            margin-block-start: 15px;
                        }
                    }

                    h5 {
                        font-family: $font-base;
                        font-size: 1.2rem;
                        margin-block-start: 3px;

                        @include mobile {
                            font-size: 1rem;
                            letter-spacing: 0.085rem;
                        }
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 50%;
                        height: 1px;
                        border-bottom: 1px solid #CECECE;
                        position: absolute;
                        inset-block-end: 80px;
                        inset-inline-end: 0;

                        @include ultra-narrow-desktop {
                            inset-block-end: 71px;
                        }
    
                        @include tablet {
                            inset-block-end: 71px;
                        }
    
                        @include narrow-tablet {
                            inset-block-end: 71px;
                        }

                        @include mobile {
                            display: none;
                        }
                    }

                    .pagebuilder-column {
                        @include mobile {
                            width: 46% !important;
                        }

                        &:first-child {
                            @include mobile {
                                border-bottom: 1px solid #CECECE;
                                width: 100% !important;
                                padding-block-end: 8px;
                            }
                        }
                    }
                }
            }

            .sfondo-column {
                position: absolute;
                width: 100%;
                height: 100%;
                inset-block-start: 0;
                inset-inline-start: 0;
            }

            .brand-slider {
                padding-block-start: 75px;

                @include mobile {
                    padding-block-start: 29px;
                }

                h3 {
                    margin-block-start: 0;
                }

                &::before {
                    display: none; 
                }

                p {
                    margin-block-end: 0;
                }
            }
        }
    }

    &#realizzazioni-forel {
        .topbanner {
            .pagebuilder-column-group {
                position: relative;
                height: auto;
                max-height: unset;
                inset-block-start: 0;
                width: 100%;
                padding-inline: 0;
                padding-block: 0;
            }

            h1 {
                position: absolute;
                inset-block-end: 0;
                inset-inline-end: 0;
                background-color: #c6a33b;
                color: #222;
                padding: 18px 26px;
                width: 447px;

                @include desktop-wide-1600 {
                    width: 380px;
                }

                @include ultra-narrow-desktop {
                    width: 350px;
                }

                @include mobile {
                    position: relative;
                    padding: 18px 18px;
                    width: 100%;
                    font-weight: 800;
                    letter-spacing: 0.17rem;
                }
            }
        }

        .section-title {
            margin-block: 0 70px;

            @include mobile {
                font-size: 2rem;
                font-weight: 700;
                margin-block: 0 20px;
            }
        }

        .elenco-storie,
        .elenco-realizzazioni {
            padding-block: 70px 90px;

            @include mobile {
                padding-block: 30px;
            }

            .pagebuilder-column-group {
                @include desktop {
                    gap: 60px;
                    margin-block-end: 60px;
                }

                @include mobile {
                    flex-direction: column;
                    gap: 0;
                    margin-block-end: 30px;
                }
            }

            .pagebuilder-column {
                justify-content: center;

                @include mobile {
                    width: 100% !important;
                }
            }

            h2 {
                @include mobile {
                    letter-spacing: 0.24rem;
                }
            }

            h3 {
                @include mobile {
                    font-size: 1.2rem;
                    line-height: 1.4;
                }
            }
        }

        .elenco-storie {
            .pagebuilder-column-group {
                &:nth-child(2) {
                    @include desktop {
                        gap: 15px;
                    }
                }

                &:nth-child(3) {
                    @include mobile {
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 15px;
                    }

                    .pagebuilder-column {
                        @include mobile {
                            width: 47% !important;
                        }

                        &.right-text {
                            @include desktop {
                                padding-inline-start: calc(8.5% - 60px);                                
                            }

                            @include ultra-narrow-desktop {
                                padding-inline-start: calc(5% - 60px); 
                            }

                            @include tablet {
                                padding-inline-start: calc(5% - 60px); 
                            }

                            @include narrow-tablet {
                                padding-inline-start: calc(5% - 60px); 
                            }

                            @include mobile {
                                width: 100% !important;
                                padding-inline-start: 0;
                            }
                        }
                    }
                }

                .pagebuilder-column {
                    &.right-text {
                        @include desktop {
                            padding-inline: 7.4% 4%;
                        }

                        @include ultra-narrow-desktop {
                            padding-inline: 5% 0;
                        }

                        @include tablet {
                            padding-inline: 5% 0;
                        }

                        @include narrow-tablet {
                            padding-inline: 5% 0;
                        }

                        @include mobile {
                            padding-inline: 0 0;
                        }
                    }

                    h2 {
                        margin-block-end: 35px;

                        @include desktop-wide-1440 {
                            margin-block-end: 20px;
                        }

                        @include narrow-desktop {
                            margin-block-end: 15px;
                        }

                        @include ultra-narrow-desktop {
                            margin-block-end: 15px;
                        }

                        @include narrow-tablet {
                            margin-block-end: 15px;
                        }

                        @include mobile {
                            margin-block-end: 20px;
                        }
                    }

                    h3 {
                        margin-block-end: 43px;
                        line-height: 1.8;

                        @include desktop-wide-1600 {
                            margin-block-end: 25px;
                            line-height: 1.6;
                        }

                        @include mobile {
                            margin-block-end: 20px;
                        }
                    }

                    p {
                        line-height: 1.8;

                        @include desktop-wide-1600 {
                            line-height: 1.7;
                        }

                        @include ultra-narrow-desktop {
                            font-size: 1.4rem;
                        }

                        @include narrow-tablet {
                            font-size: 1.4rem;
                        }

                        @include mobile {
                            line-height: 1.4;
                        }
                    }
                }
            }
        }

        .elenco-realizzazioni {
            .pagebuilder-column-group {
                gap: 25px;

                &:nth-child(2) {
                    @include desktop {
                        margin-block-end: 120px;
                    }

                    h2 {
                        width: 65%;

                        @include narrow-tablet {
                            width: 100%;
                        }

                        @include mobile {
                            margin-block-start: 18px;
                            width: 100%;
                            font-size: 1.4rem;
                        }
                    }

                    p {
                        width: 80%;

                        @include narrow-tablet {
                            width: 100%;
                        }

                        @include mobile {
                            width: 100%;
                        }
                    }
                }

                &:last-child {
                    @include mobile {
                        flex-direction: row;
                        gap: 18px;
                        overflow-x: scroll;
                        margin-inline-end: -20px;
                        margin-block-end: 0;
                    }

                    .pagebuilder-column {
                        @include mobile {
                            min-width: 70% !important;
                            border-bottom: none;
                        }
                    }
                }

                .pagebuilder-column {
                    padding-block-end: 10px;
                    border-block-end: 1px solid #CECECE;
                }

                h3 {
                    margin-block-start: 20px;
                    margin-block-end: 3px;

                    @include mobile {
                        font-size: 1.4rem;
                    }
                }

                h5 {
                    margin-block: 0;
                }
            }
        }
    }

    &#click-and-collect {
        .CmsPage-Wrapper {
            max-width: 100%;
        }

        .topbanner {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    @include mobile {
                        width: 100%;
                    }
                }

                .column.first {
                    @include mobile {
                        padding-inline-start: 0;
                    }
                }

                .column.second {
                    @include desktop-wide-1600 {
                        padding-inline-start: 5%;
                        padding-inline-end: 70px;
                    }

                    @include mobile {
                        padding-inline-start: 0;
                        padding-inline-end: 0;
                    }
                }
            }
        }

        .cc-secondblock {
            background-color: #fff;
            padding-block: 80px;
            padding-inline-start: 33.33%;
            padding-inline-end: 28.66%;

            @include ultra-narrow-desktop {
                padding-inline-start: 24%;
                padding-inline-end: 20%;
            }

            @include tablet {
                padding-inline-start: 24%;
                padding-inline-end: 20%;
            }

            @include narrow-tablet {
                padding-inline-start: 24%;
                padding-inline-end: 20%;
            }

            @include mobile {
                padding-inline-start: 18px;
                padding-inline-end: 18px;
                padding-block: 42px;
                flex-direction: column;
            }

            figure {
                max-width: 135px;
                height: 127px;

                @include ultra-narrow-desktop {
                    max-width: 90px;
                    height: auto;
                }
    
                @include tablet {
                    max-width: 90px;
                    height: auto;
                }
    
                @include narrow-tablet {
                    max-width: 90px;
                    height: auto;
                }

                @include mobile {
                    max-width: 71px;
                    height: auto;
                }
            }

            .pagebuilder-column-group {
                gap: 60px;

                @include ultra-narrow-desktop {
                    gap: 30px;
                }
    
                @include tablet {
                    gap: 30px;
                }
    
                @include narrow-tablet {
                    gap: 30px;
                }

                @include mobile {
                    flex-direction: column;
                    gap: 0;
                }

                .pagebuilder-column {
                    @include mobile {
                        width: 100%;
                    }

                    &:first-child {
                        width: fit-content;
                    }
                }

                h2 {
                    margin: 0;

                    @include ultra-narrow-desktop {
                        font-size: 2.1rem;
                    }

                    @include tablet {
                        font-size: 2.1rem;
                    }

                    @include narrow-tablet {
                        font-size: 2.1rem;
                    }

                    @include mobile {
                        margin-block-start: 15px;
                    }
                }

                h3 {
                    margin-block-end: 10px;
                }

                h4 {
                    font-size: 1.4rem;
                    font-weight: bold;
                    margin-block-end: 25px;
                }

                p {
                    font-family: $font-base;
                    font-weight: 300;
                    font-size: 1.5rem;
                    min-width: 550px;
                    margin: 0;

                    @include mobile {
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 1.5;
                        min-width: 100%;
                    }
                }
            }
        }

        .cc-thirdblock {
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
            padding-block-start: 90px;

            @include mobile {
                padding-block-start: 40px;
            }

            .pagebuilder-column-group {
                gap: 40px;

                @include mobile {
                    flex-direction: column;
                }

                .pagebuilder-column.step-column-content {
                    background-color: #fff;
                    height: 420px;
                    width: calc((100% - 80px) / 3);
                    border: none;
                    padding-inline: 40px;
                    padding-block-start: 130px;
                    margin-block-start: 70px;

                    @include ultra-narrow-desktop {
                        height: 350px;
                        padding-block-start: 80px;
                        padding-inline: 30px;
                    }

                    @include tablet {
                        height: 350px;
                        padding-block-start: 80px;
                        padding-inline: 30px;
                    }

                    @include narrow-tablet {
                        height: 350px;
                        padding-block-start: 80px;
                        padding-inline: 30px;
                    }

                    @include mobile {
                        width: 100%;
                        height: auto;
                        padding-block-start: 60px;
                        padding-block-end: 15px;
                        padding-inline: 18px;
                    }

                    &::before {
                        width: 100%;
                        background-color: #c6a33b;
                        inset-inline-start: 0;
                        inset-block-start: 0;
                        height: 35px;
                        content: '';
                        position: absolute;
                    }

                    .one, .two, .three {
                        position: absolute;
                        inset-block-start: -70px;
                        inset-inline-start: 0;
                        margin: 0;
                        max-width: 260px;
                        display: flex;
                        align-items: center;
                        line-height: 1;
    
                        @include ultra-narrow-desktop {
                            font-size: 2.1rem;
                        }
    
                        @include tablet {
                            font-size: 2.1rem;
                        }
    
                        @include narrow-tablet {
                            font-size: 2.1rem;
                        }

                        @include mobile {
                            inset-block-start: -58px;
                        }

                        &::before {
                            content: '';
                            color: #CECECE;
                            font-size: 6.9rem;
                            font-weight: normal;
                            font-family: $font-muli;
                            margin-inline-end: 15px;

                            @include mobile {
                                font-size: 5rem;
                            }
                        }
                    }

                    .one {
                        &::before {
                            content: '1';
                        }
                    }

                    .two {
                        &::before {
                            content: '2';
                        }
                    }

                    .three {
                        &::before {
                            content: '3';
                        }
                    }

                    figure {
                        width: 75px;
                        height: 75px;
                        margin-block-end: 35px;

                        @include ultra-narrow-desktop {
                            width: 60px;
                            height: 60px;
                            margin-block-end: 20px;
                        }
    
                        @include tablet {
                            width: 60px;
                            height: 60px;
                            margin-block-end: 20px;
                        }
    
                        @include narrow-tablet {
                            width: 60px;
                            height: 60px;
                            margin-block-end: 20px;
                        }

                        @include mobile {
                            margin-block-end: 15px;
                        }
                    }

                    p {
                        font-size: 1.5rem;
                        font-weight: normal;
                        color: #1A1A1A;

                        @include mobile {
                            font-size: 1.4rem;
                            line-height: 1.5;
                        }
                    }
                }

                .pagebuilder-column.step-column {
                    padding-block-end: 0;
                    height: fit-content;
                    border: none;
                    min-width: calc((100% - 80px) / 3);

                    .one, .two, .three {
                        margin: 0;
                        max-width: 260px;
                        display: flex;
                        align-items: center;
                        line-height: 1;
    
                        @include ultra-narrow-desktop {
                            font-size: 2.1rem;
                        }
    
                        @include tablet {
                            font-size: 2.1rem;
                        }
    
                        @include narrow-tablet {
                            font-size: 2.1rem;
                        }

                        &::before {
                            color: #CECECE;
                            font-size: 6.9rem;
                            font-weight: normal;
                            font-family: $font-muli;
                            margin-inline-end: 15px;
                        }
                    }

                    .one {
                        &::before {
                            content: '1';
                        }
                    }

                    .two {
                        &::before {
                            content: '2';
                        }
                    }

                    .three {
                        &::before {
                            content: '3';
                        }
                    }
                }
            }
        }

        .cc-lastblock {
            margin-block-start: 120px;
            margin-inline-start: calc(((100vw - var(--content-wrapper-width)) / 2) + (var(--content-wrapper-width) / 3));
            padding-inline-start: 75px;
            border-inline-start: 1px solid #CECECE;
            margin-block-end: 120px;

            @include ultra-narrow-desktop {
                padding-inline-start: 40px;
                max-width: 55%;
                margin-block: 90px;
            }

            @include tablet {
                padding-inline-start: 40px;
                max-width: 55%;
                margin-block: 90px;
            }

            @include narrow-tablet {
                padding-inline-start: 40px;
                max-width: 55%;
                margin-block: 90px;
            }

            @include mobile {
                max-width: calc(100% - 36px);
                margin-block-start: 43px;
                margin-inline-start: 18px;
                padding-inline-start: 0;
                border-inline-start: none;
                margin-block-end: 40px;
            }

            p {
                font-weight: 300;
                font-size: 1.5rem;
                max-width: 730px;
                line-height: 1.8;
                margin: 0;

                @include mobile {
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 1.5;
                }
            }
        }
    }

    &#negozi,
    &#punto-vendita-campobasso-zuccarelli,
    &#punto-vendita-campobasso-colle-delle-api,
    &#punto-vendita-campobasso-pepe,
    &#punto-vendita-termoli,
    &#punto-vendita-isernia {
        @include desktop {
            background-image: var(--sfondo-negozi);
            background-repeat: no-repeat;
            background-position: left 100px;
        }

        @include mobile {
            background-image: var(--sfondo-negozi-mobile);
            background-repeat: no-repeat;
            background-position: left 20px;
        }

        .CmsPage-Content {
            margin-block-start: 56px;

            @include mobile {
                margin-block-start: 0;
                padding-inline: 18px;

                .container {
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }

                .mb-5 {
                    margin-block-end: 20px !important;
                }

                .mb-10 {
                    margin-block-end: 30px !important;
                }

                .mb-14 {
                    margin-block-end: 40px !important;
                }

                div:first-child + div {
                    h2:first-child {
                        font-size: 2.4rem;
                        text-align: start;
                        margin-block-start: 0;
                        font-weight: 600;
                    }
                }

                .pagebuilder-column-group {
                    flex-direction: column;

                    .pagebuilder-column {
                        width: 100%;

                        &.mappa-container {
                            iframe {
                                height: 270px;
                            }
                        }

                        &[data-pb-style=BQTWKCE],
                        &.mappa-container {
                            margin: 0 -18px;
                            width: calc(100% + 36px);
                        }

                        &[data-pb-style=VA3IIE3] {
                            padding-inline-start: 69px;
                            margin-block-start: 15px; 

                            p {
                                margin-block-end: 0;
                                font-weight: var(--font-weight-p-mobile);
                            }
                        }
                    }
                }
            
                .p24 p {
                    font-size: var(--font-p-mobile);
                    line-height: var(--line-height-p-mobile);
                    font-weight: var(--font-weight-p-mobile);
                    margin-block-end: 0;
                }

                .product-showcase {
                    margin-block-end: 40px !important;
                }

                .banner {
                    &.collect {
                        margin-inline-start: -18px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .contatti-negozio {
        font-weight: 300;
        margin-block-start: 0;
        margin-block-end: 8px;

        @include mobile {
            text-align: start;
            font-size: 1.6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.8rem;
        }

        @include narrow-tablet {
            font-size: 1.8rem;
        }

        &::before {
            content: '';
            margin-inline-start: 0;
            margin-inline-end: 15px;
            vertical-align: middle;
        }

        &.phone {
            &::before {
                content: var(--icon-phone);
            }            
        }

        &.email {
            &::before {
                content: var(--icon-mail);
            }            
        }
    }

    .orari-negozio {
        font-size: 2.4rem;
        width: fit-content;
        min-width: 45%;

        @include mobile {
            font-size: 1.6rem;
        }

        p {
            font-size: 2.4rem;
            margin-block-end: 6px;
            border-bottom: 1px solid var(--border-color-gray);

            @include ultra-narrow-desktop {
                font-size: 2rem;
            }

            @include tablet {
                font-size: 2rem;
            }

            @include narrow-tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
                margin-block-end: 12px;
                padding-block-end: 5px;
                word-spacing: -2px;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    .slider-negozio {
        @include mobile {
            margin: 0 -18px;
            min-width: calc(100% + 36px);
        }

        .slick-dots {
            display: none !important;

            @include mobile {
                display: block !important;
                inset-block-end: 15px;

                li {
                    margin: 0 2px;
                }
            }
        }

        .slick-next, .slick-prev {
            @include mobile {
                display: none;
            }
        }
    }

    .mappa-container {
        iframe {
            @include desktop {
                height: 665px;
            }

            @include desktop-wide-1440 {
                height: 620px;
            }

            @include narrow-desktop {
                height: 555px;
            }

            @include ultra-narrow-desktop {
                height: 400px;
            }

            @include tablet {
                height: 400px;
            }
        }
    }

    &#termini-e-condizioni {
        padding-block-start: 60px;

        .CmsPage-Heading {
            display: block;
        }

        .CmsPage-Content {
            padding-block-start: 30px;

            @include mobile {
                padding-block-start: 0; 
            }

            .container {
                max-width: 1180px;

                @include ultra-narrow-desktop {
                    max-width: 990px;
                }

                @include mobile {
                    max-width: 100%;
                    padding-inline: 0;
                }
            }

            h3 {
                @include mobile {
                    font-size: 1.2rem;
                    margin-block-end: 10px;
                }
            }

            p {
                font-size: 1.4rem;

                @include mobile {
                    line-height: 1.5;
                }
            }

            .pagebuilder-button-link {
                line-height: 1.6;
            }

            .pagebuilder-column-group {
                @include mobile {
                    display: block !important;
                }

                .pagebuilder-column {
                    @include mobile {
                        width: 100% !important;
                    }
                    
                    &.mb-3 {
                        @include mobile {
                            margin-block-end: 0 !important;
                        }
                    }
                }
            }

            .Indice {
                display: flex;
                flex-wrap: wrap;

                &-Single {
                    flex: 0 0 50%;
                    line-height: 1.7;
                    font-size: 1.6rem;
                }
            }
        }
    }
}
