/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        margin-block: 0;

        &-Wrapper {
            margin-block: 0;
            padding-inline: 0;

            @include desktop {
                max-width: 100%;
            }

            @include mobile {
                width: 100%;
                max-width: 100vw;
            }
        }

        .BannerMarketing {
            padding-block: 20px 32px;
            font-family: $font-muli;
            font-size: 1.1rem;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            color: #868686;
            text-align: start;

            @include mobile {
                display: none;
            }
            
            p {
                font-size: 1.1rem;
                font-weight: 500;
                margin-block-end: 0;

                strong {
                    font-weight: 800;
                }
            }

            &.container {
                width: var(--content-wrapper-large);
            }
        }

        .product-showcase {
            padding-block-end: 50px;

            @include ultra-narrow-desktop {
                padding-block-end: 40px;
            }

            @include mobile {
                padding-block-end: 30px;
            }

            .pagebuilder-column-group {
                @include desktop {
                    column-gap: 40px;
                }

                @include ultra-narrow-desktop {
                    column-gap: 25px;
                }
            
                @include narrow-tablet {
                    column-gap: 25px;
                }
            }

            .pagebuilder-column {
                &:last-child {
                    .container.product-carousel {
                        @include desktop {
                            margin-inline-end: calc((-100vw + var(--content-wrapper-large)) / 2);
                            max-width: var(--content-wrapper-large);
                            width: var(--content-wrapper-large);
                        }
                    }
                }
            }

            &.prodotti-promo {
                .pagebuilder-column {
                    &:last-child {
                        .container.product-carousel {
                            @include desktop {
                                margin-inline-end: 0;
                                max-width: var(--content-wrapper-large);
                                width: var(--content-wrapper-large);
                            }
                        }

                        .slick-list {
                            padding-block-start: 93px;
                            overflow: visible;

                            @include ultra-narrow-desktop {
                                padding-block-start: 60px;
                            }

                            @include narrow-tablet {
                                padding-block-start: 60px;
                            }

                            @include mobile {
                                padding-block-start: 0;
                            }
                        }
                    }
                }

                &::before {
                    content: '';
                    width: calc((+100vw - var(--content-wrapper-large)) / 2);
                    height: 100%;
                    position: absolute;
                    z-index: 9;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    background-color: $default-neutral-extralight-color;
                }

                &.black-bg {
                    &::before {
                        background-color: $black;
                    }
                }
                
                .ProductListWidget {
                    margin-block-start: 0;
                    padding-block-end: 0;
                }

                .ProductsSliderWidget {
                    .slick-dots {
                        inset-block-start: 0;
                        inset-inline-start: 0;
                    }
                }
            }

            .ProductCard {
                &-FigureReview {
                    border: none;
                }

                &-Picture {
                    padding-block-end: 100%;
                }
            }

            &.prodotti-novita,
            &.prodotti-promo {
                padding-block: 83px 90px;

                @include ultra-narrow-desktop {
                    padding-block: 40px 50px;
                }
            
                @include narrow-tablet {
                    padding-block: 40px 50px;
                }

                @include mobile {
                    padding-block: 20px 10px;
                }

                h2 {
                    max-width: 55%;

                    @include desktop-wide-1440 {
                        max-width: 65%;
                    }

                    @include ultra-narrow-desktop {
                        max-width: 100%;
                    }

                    @include narrow-tablet {
                        max-width: 100%;
                    }

                    @include mobile {
                        line-height: 1.1;
                        margin-block-start: 0;
                        margin-block-end: 18px;
                    }
                }

                .ProductListWidget {
                    margin-block-start: 0;
                    padding-block-end: 0;
                }

                &.anteprima {
                    padding-block-start: 10px;

                    h2 {
                        @include desktop-wide-1600 {
                            max-width: 100%;
                        }

                        @include mobile {
                            max-width: 75%;
                        }
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        padding-block-end: 22px;
                        border-block-start: 1px solid #E3E3E3;
                    }

                    .pagebuilder-column {
                        &:last-child {
                            @include mobile {
                                min-width: 100%;
                            }
                        }
                    }
                }
            }

            &.prodotti-novita {
                @include mobile {
                    padding-block-start: 31px;
                    padding-block-end: 40px;
                }
            }
            
            &.coming-soon {
                @include mobile {
                    padding-block-end: 5px;

                    .pagebuilder-column-group {
                        display: none !important;
                    }
                }
            }   

            &.best-sellers {
                @include mobile {
                    padding-block-end: 70px;
                }

                h2.section-title {
                    @include mobile {
                        margin-block-end: 15px;
                    }
                }

                .best-seller-box {
                    @include mobile {
                        width: 100% !important;
                        align-items: center;
                        padding-inline-end: 0;
                        position: absolute;
                        inset-block-end: -430px;
                        z-index: 10;
                    }

                    figure {
                        a {
                            img {
                                @include mobile {
                                    display: none;
                                }
                            }

                            &::after {
                                @include mobile {
                                    @include button;

                                    content: 'Scoprili tutti';
                                    font-family: $font-muli;
                                    display: block;
                                    box-sizing: border-box;
                                    min-width: 198px;
                                    height: 43px;
                                    max-width: 198px;
                                    text-align: center;
                                    box-shadow: 8px 9px #DEDEDE;
                                    font-weight: 400;
                                    font-size: 1.6rem;
                                    text-transform: none;
                                }
                            }
                        }
                    }

                    & + div {
                        @include mobile {
                            display: none !important;
                        }
                    }
                }
            }
        }

        .container {
            &.four-products {
                .Pagination {
                    display: none;
                }
            }
        }

        .coming-soon-banner {
            @include mobile {
                margin-block-end: 25px !important;

                h2.evidenza {
                    margin-block: 0 10px;
                }
    
                h3 {
                    margin-block-end: 20px !important;
                }
            }
        }

        .punti-vendita {
            .section-title {
                @include mobile {
                    text-align: start !important;
                }
            }

            h3 {
                @include mobile {
                    display: none;
                }
            }

            &.product-showcase {
                .pagebuilder-button-link {
                    @include mobile {
                        inset-block-end: 20px;
                        inset-inline-start: calc((-100vw + 198px) / 2);
                    }
                }
            }

            .StoreSliderWidget {
                @include mobile {
                    margin-block-start: 0;
                }
            }
        }
    }
}

