/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.MyAccount-Heading {
    &:has(+ .AmastyNewReturn) {
      display: none;
    }
}

.AmastyNewReturn {
    width: 100%;
    margin: 0;

    @include mobile {
        margin-block-end: 65px;
    }

    .Form {
        margin-block-start: 80px;

        @include mobile {
            margin-block-start: 25px;
        }
    }

    .amrma-text {
        font-size: 1.3rem;
    }

    .amrma-product-information strong {
        font-weight: 700;
    }

    .amrma-header {
        color: #1A1A1A;
        font-size: var(--h1-font-size);
        font-weight: 600;
        margin-block-end: 55px;
        text-align: center;

        @include narrow-desktop {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include ultra-narrow-desktop {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include tablet {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include narrow-tablet {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include mobile {
            font-size: 2rem;
            letter-spacing: 0.074rem;
            text-align: start;
            border-bottom: none;
            margin-block-start: 22px;
            margin-block-end: 0;
        }
    }

    ._strong {
        font-weight: 600;
    }

    .amrma-customer-information {
        display: flex;
        margin-block-end: 50px;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            margin-block-end: 20px;
        }

        .amrma-customer-name {
            margin-block-end: 20px;

            @include mobile {
                margin-block-end: 30px;
            }
        }
    }


    .amrma-customer-name, .amrma-customer-address {
        flex-basis: 50%;
    }

    .amrma-products-block {
        border-block-start: 1px solid #DEDEDE;
        padding-block-start: 45px;

        @include mobile {
            border-block-start: 1px solid #CECECE;
            padding-block-start: 30px;
            padding-inline-start: 0;
        }

        h2 {
            font-family: $font-muli;
            margin: 0;
            margin-block-end: 0;
            font-size: var(--h2-font-size);
            font-weight: 600;
            text-transform: none;

            @include narrow-desktop {
                font-size: var(--h2-font-size-narrow);
                letter-spacing: 0.2rem;
            }
    
            @include ultra-narrow-desktop {
                font-size: var(--h2-font-size-narrow);
                letter-spacing: 0.1rem;
            }

            @include tablet {
                font-size: var(--h2-font-size-narrow);
                letter-spacing: 0.1rem;
            }
    
            @include narrow-tablet {
                font-size: var(--h2-font-size-narrow);
                letter-spacing: 0.1rem;
            }
            
            @include mobile {
                font-size: 1.4rem;
                font-weight: 700;
                letter-spacing: normal;

                &.amrma-title {
                    max-width: 100%;
                    margin-block-end: 10px;
                }
            }
        }
    }

    .amrma-products-table {
        margin-block-end: 40px;
    }

    .amrma-product-item {
        display: flex;
        justify-content: space-between;
        padding: 40px 0 50px;
        border-bottom: 1px solid #DEDEDE;

        @include mobile {
            padding: 20px 0;
            border-bottom: 1px solid #CECECE;
            flex-direction: column;
        }
    }

    .ReturnShippingMode  {
        width: 100%;
        height: auto;
        background-color: #F0F0F0;
        padding-inline-start: 22px;
        margin-block-start: 40px;

        @include mobile {
            padding-inline-start: 0;
            margin-block-start: 30px;
        }

        h4 {
            font-size: var(--h2-font-size);
            letter-spacing: 0.2rem;
            font-weight: 600;
            text-transform: none;

            @include desktop-wide-1600 {
                font-size: var(--h2-font-size-wide);
                font-weight: 600;
            }
    
            @include ultra-narrow-desktop {
                font-size: var(--h2-font-size-narrow);
                margin-block-end: 18px;
            }
    
            @include tablet {
                margin-block-end: 15px;
                font-size: var(--h2-font-size-narrow);
            }
    
            @include narrow-tablet {
                margin-block-end: 15px;
                font-size: var(--h2-font-size-narrow);
            }

            @include mobile {
                margin-block-end: 15px;
                font-size: 1.4rem;
                font-weight: 700;
                letter-spacing: normal;
            }
        }

        .rma-instore-radio-button {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            width: 100%;
            padding-block-end: 20px;
        }


        .Rma-Select {
            width: 50%;
            display: none;

            .Field.Field_type_select {
                margin-block-start: 0px;
                margin-inline-start: 30px;
            }

            &_isSelectRequired {
                display: block;
            }
        }

        @include mobile {
            margin-block-start: 35px;
        }
    }

    .amrma-product {
        display: flex;

        @include mobile {
            align-items: center;
        }
    }

    .amrma-checkbox-container {
        display: flex;
        position: relative;

        .amrma-label {
            position: absolute;
            inset-block-start: 44px;
            width: 13px;
            height: 13px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 3px;
            background: #fff;
            cursor: pointer;
        }

        .amrma-image {
            cursor: pointer;
        }
    }

    .amrma-checkbox-container.-policy {
        align-items: baseline;
    }

    .amrma-image {
        display: block;
        margin-inline-end: 20px;
        padding-inline-start: 33px;
        width: 100px;
        height: 100px;
    }

    .amrma-image img {
        max-width: 100%;
        max-height: 100%;
    }

    .amrma-product-information {
        align-self: baseline;
    }

    .amrma-item-name {
        font-size: 1.4rem;
    }

    .amrma-product-message {
        width: 400px;

        @include desktop-wide-1600 {
            max-width: 50%;
        }
 
        @include narrow-desktop {
            width: 50%;
        }

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 100%;
            max-width: unset;
            margin-block-start: 20px;
        }
    }

    .amrma-message-container.-gray {
        margin: 0;
        box-sizing: border-box;
        border: none;
        background: none;
    }


    .amrma-input-block .Field-Label, .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
    }

    .amrma-message-container {
        margin: 10px 20px 20px;
        border-radius: 2px;
        background: #eaf1ff;
    }

    .amrma-message-container.-options {
        margin: 0;
        background: none;
    }

    .amrma-bank-details, .amrma-return-instructions, .amrma-header-block {
        margin-block-end: 40px;
    }

    .amrma-return-select {
        margin-inline: 20px;
        margin-block-end: 20px;

        @include mobile {
            margin-inline: 0;
        }

        .Field {
            margin-block-start: 0;
        }
    }

    .amrma-return-qty {
        display: flex;
        align-items: center;
        margin: 5px 0 25px 20px;

        @include mobile {
            margin: 0;
            margin-block-start: 30px;
            margin-block-end: 25px;
        }

        .amrma-label {
            margin-inline-end: 35px;
            font-weight: 500;
            font-size: 1.2rem;
            font-family: $font-base;
            color: #000000;
        }

        .amrma-total {
            font-size: 1.2rem;
            margin-inline-start: 10px;
        }

        .amrma-qty-select {
            display: flex;
            align-items: baseline;
        }

        .Field {
            margin: 0;
        }
    }


    .amrma-title {
        font-weight: normal;
        font-size: 1.6rem;
        margin-block-end: 7px;
        font-family: $font-base;

        @include ultra-narrow-desktop {
            font-size: 1.4rem;
        }

        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.4rem;
        }
    }

    .amrma-return-select .amrma-title {
        margin-block-start: 10px;
        font-size: 1.2rem;
        font-family: $font-base;
        font-weight: 500;
        text-transform: none;
    }

    .amrma-img-text {
        margin-block-end: 10px;
        padding: 5px 10px;
        border-radius: 2px;
        background: #f5f5f5;
    }

    .amrma-comment-block .amrma-title {
        font-size: 2.1rem;
        margin-block-start: 50px;
        margin-block-end: 15px;

        @include narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.6rem;
        }

        @include mobile {
            font-size: 1.8rem;
            margin-block-start: 35px;
            max-width: 100%;
        }
    }

    .amrma-comment-block .amrma-comment {
        font-size: 1.4rem;
        font-family: $font-base;
        width: 100%;
    }

    .amrma-return-policy {
        display: inline-block;
        margin-block-start: 10px;

        @include mobile {
            display: block;
        }
    }

    .amrma-attach-file {
        .amrma-attach {
            display: none;
        }

        .amrma-label::before {
            position: absolute;
            inset-block-start: 4px;
            inset-inline-start: 0;
            width: 17px;
            height: 16px;
            content: '';
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=');
        }


        .amrma-label {
            margin: 10px;
            position: relative;
            padding-inline-start: 20px;
            font-weight: bold;
            font-size: 16px;
            cursor: pointer;
            color: #DF6F2C;
            transition: color .3s ease;
        }

        .amrma-label:hover {
            text-decoration: underline;
            color: #DF6F2C;
        }

        .amrma-file {
            margin: 5px 0;
            padding-inline-start: 20px;
            color: #1979c3;
        }
    }

    .amrma-chat-file.-newreturn {
        display: flex;
        align-items: center;
        margin-inline-start: 30px;

        .amrma-file {
            padding: 0;
        }

        .amrma-delete {
            padding: 0;
            margin-inline-start: 10px;
        }
    }

    .amrma-chat-file.-newreturn::before {
        inset-block-start: 7px;
    }


    .amrma-delete {
        display: inline-block;
        margin-inline-start: 10px;
        width: 16px;
        height: 16px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNiAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM1LjkxOCAyLjcxNEwzMy4yMDMgMCAxOC40MTggMTQuNzg3IDMuNjMzIDAgLjkxOCAyLjcxMyAxNS43MDUgMTcuNS45MiAzMi4yODcgMy42MzMgMzVsMTQuNzg1LTE0Ljc4NiAxNC43ODUgMTQuNzg1IDIuNzExLTIuNzEzTDIxLjEzMSAxNy41IDM1LjkxOCAyLjcxNCIgZmlsbD0iIzAwNmJiNCIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        cursor: pointer;
    }

    ._nomargin {
        margin: 0;
    }

    .amrma-chat-file {
        position: relative;
        padding-inline-start: 25px;

        .amrma-file {
            font-weight: 600;
        }
    }

    .amrma-chat-file::before {
        position: absolute;
        inset-inline-start: 0;
        width: 17px;
        height: 16px;
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=');
    }

    .amrma-chat-file.-preview {
        display: flex;
        align-items: center;
        margin: 25px;
        padding-inline-end: 20px;
        color: #006bb4;
    }


    .btn {
        margin: 25px 25px 25px 0;
    }

    .amrma-returns-container {
        display: flex;

        .amrma-return-link {
            margin-inline-end: 10px;
        }
    }

    .amrma-return-policy-required {
        color: #e02b27;
    }

    .amrma-btns-block {
        display: flex;
        justify-content: center;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-block-start: 30px;
        }
    }

    .amreturn-btn, .amrma-link-cancel {
        width: 100%;
        max-width: 225px;      
        margin-block-start: 55px;

        @include mobile {
            max-width: 240px;
            margin-block-start: 0;
            margin-inline-end: 0;
        }
    }

    .amreturn-btn {
        background-color: #0D0D0D;
        color: #fff;
        border: none;

        &:hover {
            background-color: #454545;
            color: #fff;
            border: none;
        }
    }

    .rma-radio-button {
        .Field {
            &.Field_type_radio label:first-of-type {
                display: block;

                .label-text {
                    display: inline-block;
                    width: calc(100% - 30px);

                    @include mobile {
                        width: calc(100% - 40px); 
                    }
                }
            }

            input {
                &[type=radio] {
                    padding: 0;
                    border-radius: 50%;

                    + .input-control {
                        border-radius: 12px;
                        display: inline-block;
                        vertical-align: top;
                        width: 20px;
                        height: 20px;
                        max-width: 20px;
                        border-radius: 50%;
                        border: 2px solid #000000;

                        &::after {
                            display: none;
                            box-shadow: none;
                            content: '';
                            height: 8px;
                            width: 8px;
                            background-color: #000000;
                            inset-block-start: 4px;
                            inset-inline-start: 4px;
                            transform: none;
                            border-radius: 10px;
                            transition: none;

                            @include mobile {
                                height: 9px;
                                width: 9px;
                            }
                        }
                    }

                    &:hover:not(:disabled) + .input-control {
                        --box-color: #fff;

                        border: 2px solid #000000;
                        margin-inline-end: 11px;
                    }

                    &:checked + .input-control {
                        border: 2px solid #000000;

                        &::after { 
                            display: block;
                        }
                    }
                }
            }
        }

        .Field-RadioLabel {
            align-items: flex-start;
            font-size: 1.5rem;
        }

        .Field-RadioLabel span, .Field.Field_type_radio {
            display: block;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}
