/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 * Override Sidea */

:root {
    --loader-scale: 1;
}

.Loader {
    background-color: transparent;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        inset-inline-start: 50%;
        inset-block-start: calc(50% - (3px * var(--loader-scale)));

        &:dir(rtl) {
            transform: translate(50%, -50%) scale(var(--loader-scale));
        }
    }

    &-Main {
        @keyframes loading-bar {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0.5;
            }

            100% {
                opacity: 1;
            } 
        }

        svg {
            fill: $default-primary-base-color !important;
            opacity: 1;
            animation: loading-bar 1s infinite 1s;
        }
    }    
}
