/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    @include mobile {
        border-bottom: 1px solid #DEDEDE;
        padding-block-end: 40px;
        margin-block-end: 0;
        padding-block-start: 20px;
    }

    .ContentWrapper {
        max-width: var(--content-wrapper-large);
    }

    h1 {
        @include mobile {
            letter-spacing: 0.24rem;
            padding-block-end: 15px;
            border-block-end: 1px solid #ccc;
        }
    }

    h2 {
        @include mobile {
            letter-spacing: 0.085rem;
        }
    }

    .pagebuilder-button-primary {
        background-color: $black;
        border-color: $black;
        color: $white;
        max-width: 178px;
        min-width: 178px;
        padding-inline: 20px !important;

        span {
            font-weight: 500;
        }

        &:hover {
            background-color: #454545;
            border-color: #454545;
            color: $white;
        }
    }

    .button_call {
        @include desktop {
            display: none;
        }

        @include mobile {
            position: absolute;
            inset-block-start: 15px;
            inset-inline-end: 0;
        }

        .pagebuilder-button-primary,
        .Button {
            max-width: 140px;
            min-width: 140px;
            padding-inline: 10px !important;

            &::before {
                margin-inline-end: 8px;
                margin-block-start: 8px;
                content: var(--small-phone);
                filter: invert(100%);
            }
        }
    }

    &-Heading {
        height: fit-content;
        margin-block-start: 50px;
        margin-block-end: 50px;
        font-size: 7.2rem;
        font-weight: 300;

        @include desktop-wide-1600 {
            font-size: 6.5rem;
        }
    
        @include desktop-wide-1440 {
            font-size: 6rem;
            margin-inline-start: -50px;
        }
    
        @include narrow-desktop {
            font-size: 5.8rem;
        }
    
        @include ultra-narrow-desktop {
            font-size: 4.5rem;
        }
    
        @include tablet {
            font-size: 4.5rem;
            margin-block-start: 35px;
        }
    
        @include narrow-tablet {
            font-size: 4rem;
        }

        @include mobile {
            margin-block-start: 10px;
            margin-block-end: 20px;
            font-size: 2.7rem;
        }

        strong {
            font-weight: 600;
            line-height: 1.2;
        }
    }

    .contatti-head-container {
        @include desktop {
            min-width: 265px;            
        }

        h6 {
            font-size: 1.2rem;
            margin-block-end: 7px;

            @include mobile {
                margin-block-end: 0;
                margin-block-start: 10px;
            }
        }

        div {
            &:nth-child(3) {
                margin-block-start: 10px;
                padding-block-start: 24px;
                border-top: 1px solid #ccc;

                @include mobile {
                    padding-block-start: 10px;
                    border-top: none;
                }
            }
        }
    }

    .Form {
        @include mobile {
            .col-md-6 {
                padding-inline-start: 0;
                padding-inline-end: 0;
                margin-block-end: 10px;
            }
    
            .Field {
                margin-block-start: 10px;

                @include mobile {
                    margin-block-start: 0;
                }
            }

            .FieldSelect-Select {
                margin-block-start: 0;
            }
        }

        .FieldSelect-Select {
            min-height: 50px;
        }

        .Button {
            @include mobile {
                margin: 0 auto;
                display: block;
                width: 178px;
                max-width: 100%;
                height: 40px;
                margin-block-start: 27px;
                margin-block-end: 50px;
            }
        }
    }
}
