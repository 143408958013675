/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override style */

:root {
    --header-top-menu-height: 40px;
    --header-logo-height: 46px;
    --header-logo-width: 165px;
    --topmenu-height: 40px;

    @include ultra-narrow-desktop {
        --header-top-menu-height: 40px;
        --topmenu-height: 40px;
    }

    @include mobile {
        --topmenu-height: 30px;
    }
}

.Header {
    position: fixed;
    inset-inline-start: 0;
    border-bottom: none;
    background-color: $black;
    color: $white;
    font-family: $font-muli;
    letter-spacing: 0.06rem;

    button, a {
        & > * {
            text-transform: uppercase;
        }
    }

    svg {
        fill: $white;
    }

    & > * {
        max-width: 100vw;
        width: 100%;
    }

    .Menu-SubCategories {
        a {
            width: 100%;
            display: block;
        }

        figcaption {
            width: fit-content;
            padding-block-end: 3px;
            border-bottom: 1px solid $white;

            &:hover {
                border-bottom: 1px solid $default-neutral-base-color;
            }
        }

        figcaption, a {
            font-size: 1.1rem;
            color: $default-neutral-base-color;

            &:hover {
                color: $default-neutral-base-color;
            }
        }
    }

    &-TopLinks {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.2rem;
        padding-block-start: 13px;
        padding-block-end: 6px;
        padding-inline-start: calc( (100% - var(--content-wrapper-nav-width)) / 2);
        padding-inline-end: calc( (100% - var(--content-wrapper-nav-width)) / 2);

        @include ultra-narrow-desktop {
            font-size: 1rem; 
        }

        @include tablet {
            font-size: 10px;
        }

        a {
            margin-inline-start: 12px;
            padding-inline-end: 12px;
            color: $white;

            &:hover {
                color: $default-primary-base-color;
            }

            &::after {
                content: "|";
                font-size: 10px;
                position: absolute;
                inset-inline-end: 0;
            }

            &:last-child {
                padding-inline-end: 0;

                &::after {
                    display: none;
                }
            }
        }

        @include mobile {
            display: none;
        }
    }

    &-TopMenu {
        background-color: $black;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: var(--header-top-menu-height);
        margin: auto;
        max-width: 100%;
        padding-inline: 0;
        padding-block-start: 0;
        padding-block-end: 0;
        min-height: var(--topmenu-height);
        font-size: 1.1rem;
        text-transform: uppercase;
        text-align: center;

        &#topMenu {
            display: none;

            @include mobile {
                display: flex;
            }
        }

        @include mobile {
            height: 35px;
            min-height: 35px;
            font-size: 10px;
            padding-inline: 20px;
            text-align: center;
            white-space: nowrap;
        }

        p {
            font-size: inherit;
            margin-block-end: 6px;

            @include narrow-tablet {
                font-size: 1rem;
            }
        }

        .container-full {
            margin-inline-start: 0;
        }

        .CmsBlock-Wrapper {
            width: 100%;

            & > div {
                & > div {
                    display: block !important;
                }
            }

            .single-text {
                display: inline-block;
                vertical-align: middle;
                width: fit-content;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }

                p {
                    @include mobile {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    &-BottomMenu {
        background-color: $default-neutral-base-color;
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        padding-inline: calc( (100% - var(--content-wrapper-nav-width)) / 2);
        font-size: 0.95rem;
    }

    &-Values, 
    .Menu-ViewAllWrapper {
        .Button-Text {
            font-family: $font-base;
            font-size: 0.95rem;
            font-weight: 900;
            text-transform: uppercase;

            @include narrow-tablet {
                font-size: 0.85rem;
            }

            &::before {
                width: 14px;
                height: 14px;
                margin-inline-end: 7px;
                margin-block-start: -2px;
                background-color: $default-neutral-base-color;
            }

            &:hover {
                &::before {
                    background-color: $default-primary-light-color;
                }
            }
        }
    }

    &-Values {
        display: flex;
        font-family: $font-base;
        letter-spacing: 1px;
        font-weight: 300;
        text-transform: uppercase;

        @include narrow-tablet {
            font-size: 0.85rem;
        }

        button, a {
            color: $white;
    
            &:hover {
                color: $default-primary-base-color;
            }
    
            & > * {
                color: $white;
                text-transform: uppercase;
    
                &:hover {
                    color: $default-primary-base-color;
                }
            }
        }
        
        .Button-Text {
            margin-inline-start: 23px;


            &::before {
                background-color: $white;
            }

            &:hover {
                &::before {
                    background-color: $default-primary-base-color;
                }
            }
        }
    }

    &-CollapseHead {
        display: flex;

        @include mobile {
            height: auto;
            flex-direction: column;
            gap: 20px;
            margin-block-end: 15px;
        } 
    }

    &-CollapseContainer {
        display: block;

        .Content-ContentCollapse {
            position: absolute;
            inset-inline-start: 23px;
            inset-block-start: 25px;
            z-index: 99;
            background-color: #222222;
            width: 500px;
            height: auto;
            padding-block: 15px;
            padding-inline: 20%;  
        
            p {
                color: #fff;
                font-family: $font-base;
                font-size: 1.2rem;
                line-height: 1;
                margin: 0;
            }
        }
    }

    &-ContentCollapse {
        @include mobile {
            position: absolute;
            z-index: 99;
            background-color: #222222;
            height: auto;
            padding-block: 15px;
            padding-inline: 7px; 
            inset-block-start: 15px;
            inset-inline-start: 0;
            width: 100%;         

            p {
                color: #fff;
                font-family: $font-base;
                font-size: 1.2rem;
                line-height: 1;
                margin: 0;
            }
        }
    }

    &-ButtonCollapse {
        @include mobile {
            color: #000;
            padding-inline: 0px;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            font-family: $font-muli;
        }
    }

    .Logo {
        display: flex;
        background: transparent;

        @include ultra-narrow-desktop {
            width: 150px;
        }

        @include tablet {
            width: 150px;
            padding-block-start: 5px;
        }

        @include narrow-tablet {
            padding-block-start: 8px;
            width: 120px;
        }
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &-Nav {
        display: flex;

        @include desktop {
            align-items: flex-end;
            justify-content: flex-start;
            max-width: 100%;
            padding-inline: calc( (100% - var(--content-wrapper-nav-width)) / 2);
            height: auto;
            max-height: 75px;
            padding-block-start: 15px;
        }

        @include ultra-narrow-desktop {
            max-height: 65px;
            padding-block-start: 9px;
        }

        @include tablet {
            max-height: 65px;
            padding-block-start: 9px;
        }

        &.Homepage {
            border-bottom: none;
        }
    }

    .NavigationTabs {
        @include mobile {
            &_isHidden {
                display: block;
            }
        }
    }

    &-MyAccount {
        height: auto;

        button,
        a {
            font-family: $font-muli;
            color: $white;
            font-weight: 600;
            font-size: 1.1rem;
            padding-inline: 12px;
            height: 40px;
            border-inline-start: 1px solid #3C3C3B;

            @include desktop {
                cursor: pointer;
                vertical-align: bottom;
            }

            @include ultra-narrow-desktop {
                font-size: 1rem;
            }

            @include narrow-tablet {
                font-size: 1rem;
            }

            span {
                color: $white;
            }

            &:hover span {
                color: $default-primary-base-color;
            }
        }

        &Wrapper {
            &.active {
                background-color: $default-primary-base-color;

                span {
                    color: $default-neutral-base-color;
                }

                &:hover {
                    span {
                        color: $default-neutral-base-color;
                    } 
                }
            }
        }
    }

    &-MyAccountContainer {
        margin-inline-start: 0;
        
        @include ultra-narrow-desktop {
            padding-inline-end: 0 !important;
        }

        @include tablet {
            padding-inline-end: 0 !important;
        }

        .MyAccountOverlay {
            &-SignInButton,
            &-NewCustomer {
                Button,
                .Button {
                    border-inline-start: 1px solid $black;
                    width: 100%;
                    font-size: 1.1rem;
                    font-weight: 600;
                    background-color: $black;
                    color: #ffffff;
                    border: 1px solid $black;
                    margin-block: 0 !important;
                    max-width: 202px;

                    &:hover {
                        background-color: #454545 !important;
                        color: #ffffff !important;
                        border: 1px solid #454545 !important;
                    }
                }
            }

            .SocialLoginButtons-Heading h4 {
                background-color: #ffffff;
            }
        }
    }

    &-LogoWrapper {
        position: relative;

        @include desktop {
            height: 100%;
            margin-inline-end: 30px;
            width: unset;
        }

        @include desktop-wide-1440 {
            margin-inline-end: 15px;
        }

        @include ultra-narrow-desktop {
            margin-inline-end: 0px;
            max-width: 150px;
            overflow: visible;
        }

        @include tablet {
            margin-inline-end: 0px;
            max-width: 150px;
            overflow: visible;
        }

        @include narrow-tablet {
            max-width: 130px;
        }

        @include mobile {
            opacity: 1;
        }

        .Image-Image {
            width: auto;
            height: auto;
            position: relative;

            @include desktop-wide-1600 {
                width: 163px;
                max-width: 100%;
            }

            @include ultra-narrow-desktop {
                max-width: 130px;
                margin-block-start: 6px;
            }

            @include narrow-tablet {
                max-width: 100%;
            }

            @include mobile {
                width: 115px;
            }
        }
    }

    &-IconsWrapper {
        align-items: flex-end;
        justify-content: flex-end;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.1rem;
        
        > div {
            line-height: 1;

            &:first-child {
                padding-inline-start: 0;
            }

            &:last-child,
            &:nth-last-child(2) {
                border-right: none;
            }

            &:last-child {
                @include ultra-narrow-desktop {
                    padding-inline-end: 0;
                }

                @include tablet {
                    padding-inline-end: 0;
                }
            }
        }

        svg {
            @include ultra-narrow-desktop {
                transform: scale(0.9);
            }

            @include tablet {
                transform: scale(0.9);
            }
        }

        .AccountIcon {
            @include ultra-narrow-desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }

    &-CompareButtonWrapper,
    &-StoreButtonWrapper {
        margin-inline-start: 0;
        margin-inline-end: 20px;

        a {
            all: unset;
            cursor: pointer;
            padding-inline: 12px;
            height: 40px;
            line-height: 40px;
            border-inline: 1px solid #3C3C3B;
            
            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-StoreButtonWrapper {
        height: 16px;
                
        &::after {
            content: "|";
            font-size: 11px;
            margin-inline-start: 12px;
            vertical-align: bottom;
            display: inline-block;
            margin-block-end: 2px;

            @include ultra-narrow-desktop {
                margin-inline-start: 11px;
                margin-block-end: 0px;
            }

            @include tablet {
                margin-inline-start: 11px;
                margin-block-end: 0px;
            }
        }

        @include tablet {
            padding-inline-end: 0;
        }

        svg {
            transform: scale(0.84);
            margin-block-start: -13px;
            margin-inline-end: 1px;

            @include tablet {
                transform: scale(0.7);
                vertical-align: bottom;
            }
        }
    }

    &-CompareButtonWrapper {
        height: auto;

        a {
            display: block;
            font-size: 1.1rem;

            @include ultra-narrow-desktop {
                font-size: 1rem;
                line-height: 41px;
            }

            @include narrow-tablet {
                font-size: 1rem;
                line-height: 41px;
            }
        }

        svg {
            transform: scale(0.75);

            @include ultra-narrow-desktop {
                transform: scale(0.6);
            }

            @include tablet {
                transform: scale(0.6);
            }

            &:hover {
                path {
                    fill: $default-primary-base-color;
                }
            }
        }
    }

    &-MinicartButtonWrapper {
        svg {
            fill: $default-neutral-dark-color;
        }

        span {
            color: $black;
            font-size: 1.2rem;
            font-weight: 700;

            @include narrow-desktop {
                font-size: 1.1rem;
            }

            @include tablet {
                font-size: 1.1rem;
            }

            &:hover {
                color: $black;
            }

            &.Header-MinicartItemCount {
                background-color: #fff;
                border: 1px solid $black;
                height: 23px;
                min-width: 23px;
                border-radius: 50%;
                font-family: $font-muli;
                font-weight: 700;
                color: $black;
                text-align: center;
                inset-inline-start: auto;
                inset-inline-end: -6px;
                inset-block-start: -6px;

                &::before {
                    display: none;
                }
            }
        }
    }
 
    &-Button {
        &_type {
            &_minicart {
                height: 40px;
                width: 40px;
                padding-inline-end: 0;
                background-color: $default-primary-base-color;
                
                @include desktop {
                    margin-inline-start: 0;
                }

                @include tablet {
                    padding-inline-start: 0;
                }

                &:hover {
                    background-color: $default-primary-base-color;
                }

                .CartIcon {
                    fill: var(--primary-base-color);
                    width: 21px;
                    height: 21px;
                    vertical-align: middle;
                }

                svg {
                    &:hover {
                        path {
                            fill: $default-neutral-dark-color;
                        }
                    }
                }
            }
        }
    }

    .SearchField {
        @include mobile {
            position: absolute;
            z-index: 5;
            inset-block-start: 60px;
            display: block;
            width: 100%;
            background-color: #222222;
            padding-inline: 0;
            padding-block-end: 0;
            margin-block: 0;
            transform: translateY(-120px);
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }

        &.isVisible {
            @include mobile {
                transform: translateY(0);
            }
        }

        &_isActive {
            .SearchOverlay {
                &-Results {
                    @include ultra-narrow-desktop {
                        min-width: 300px;
                        inset-inline-end: 0;
                        inset-block-start: 0;
                    }
                }
            }
        }

        &-SearchInnerWrapper {
            @include ultra-narrow-desktop {
                margin-inline-end: 43px;
            }

            @include tablet {
                margin-inline-end: 43px;
            }

            @include narrow-tablet {
                margin-inline-end: 43px;
            }
        }

        &-Input {
            @include ultra-narrow-desktop {
                cursor: pointer;
                position: absolute;
                z-index: 20;
                padding-inline-start: 40px;
                height: 30px;
                inset-block-end: 0;
                inset-inline-end: 0;
                width: 36px;
                min-width: unset;
                background-color: transparent;
                border: none;
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -ms-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }

            @include tablet {
                cursor: pointer;
                position: absolute;
                z-index: 20;
                padding-inline-start: 40px;
                height: 30px;
                inset-block-end: 0;
                inset-inline-end: 0;
                width: 36px;
                min-width: unset;
                background-color: transparent;
                border: none;
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -ms-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }

            @include narrow-tablet {
                cursor: pointer;
                position: absolute;
                z-index: 20;
                padding-inline-start: 40px;
                height: 30px;
                inset-block-end: 0;
                inset-inline-end: 0;
                width: 36px;
                min-width: unset;
                background-color: transparent;
                border: none;
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -ms-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }

            &_isActive {
                @include ultra-narrow-desktop {
                    cursor: text;
                    width: 300px;
                    padding-inline-start: 20px;
                    background-color: #000;
                    border-block-end: 1px solid $default-primary-base-color;
                    z-index: 0;
                }

                @include tablet {
                    cursor: text;
                    width: 300px;
                    padding-inline-start: 20px;
                    background-color: #000;
                    border-block-end: 1px solid $default-primary-base-color;
                    z-index: 0;
                }

                @include narrow-tablet {
                    cursor: text;
                    width: 300px;
                    padding-inline-start: 20px;
                    background-color: #000;
                    border-block-end: 1px solid $default-primary-base-color;
                    z-index: 0;
                }
            }
        }

        &-SearchIcon,
        &-CloseIcon {
            @include ultra-narrow-desktop {
                cursor: pointer;
                inset-inline-start: auto;
                inset-inline-end: 7px;
                inset-block-end: 5px;
            }

            @include tablet {
                cursor: pointer;
                inset-inline-start: auto;
                inset-inline-end: 7px;
                inset-block-end: 5px;
            }

            @include narrow-tablet {
                cursor: pointer;
                inset-inline-start: auto;
                inset-inline-end: 7px;
                inset-block-end: 5px;
            }
        }
    }

    &#HeaderHomepage {
        .SearchField {
            @include mobile {
                background-color: $default-neutral-base-color;
                color: $white;
                position: relative;
                inset-block-start: 0;
                transform: translateY(0);
                box-shadow: none;
                padding: 0;
            }
        }

        .Button-SearchIcon {
            display: none;
        }
    }

    .MenuSearch-Wrapper {
        display: flex;
        gap: 10%;
        align-items: flex-end;
        width: 90%;
        
        @include narrow-desktop {
            gap: 30px;
        }

        @include ultra-narrow-desktop {
            gap: 30px;
        }

        @include tablet {
            gap: 30px;
        }

        @include narrow-tablet {
            gap: 30px;
        }
    }

    .Menu {
        @include desktop {
            &-MainCategories {
                padding-inline-start: 0;
                width: auto;
            }

            &-MenuWrapper {
                margin-block-start: 0;
                max-width: var(--content-wrapper-large);
                display: flex;
                align-items: flex-end;
                position: unset;
            }

            &-ItemList {
                &_type_subcategory {
                    height: auto;
                }
            }

            &-RightButtonsWrapper {
                --button-height-mini: 35px;
                
                @include ultra-narrow-desktop {
                    --button-height-mini: 30px;
                }
                
                .Button {
                    height: var(--button-height-mini);
                    min-width: 172px;
                    margin-inline-start: 3px;

                    @include desktop-wide-1440 {
                        min-width: 150px;
                    }

                    @include narrow-desktop {
                        min-width: 150px;
                    }

                    @include ultra-narrow-desktop {
                        min-width: 120px;
                        height: 30px;
                    }

                    @include tablet {
                        min-width: 120px;
                        height: 30px;
                    }

                    &.newProducts {
                        background-color: $default-secondary-light-color;
                        border-color: $default-secondary-light-color;
                        color: $default-secondary-base-color;

                        &:hover {
                            background-color: $default-secondary-base-color;
                            border-color: $default-secondary-base-color;
                            color: $white;
                        }
                    }

                    &.sales {
                        background-color: $default-primary-light-color;
                        border-color: $default-primary-light-color;
                        color: $default-primary-base-color;

                        &:hover {
                            background-color: $default-primary-base-color;
                            border-color: $default-primary-base-color;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &-HomeWrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 115px;

        @include mobile {
            margin-block-end: 140px;
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 145px;

        @include mobile {
            margin-block-end: 140px;
        }
    }

    &_isCheckout {
        .Header-TopLinks {
            display: none;
        }
        
        .Header-LogoWrapper {
            max-width: 300px;

            @include mobile {
                max-width: fit-content;
            }

            .Logo {
                width: auto;
            }
        }

        .NavigationTabs {
            &-Button {
                &.Logo {
                    @include mobile {
                        justify-content: center;
                    }
                }
            }
        }
    }

    #conveythis {
        max-width: 60px;

        @include desktop {
            position: absolute;
            inset-inline-end: 4.8%;
            inset-block-start: 15px;
        }

        @include desktop-wide-1440 {
            inset-block-start: 10px;
            inset-inline-end: 10px;
        }

        @include narrow-desktop {
            inset-block-start: 10px;
            inset-inline-end: 10px;
        }

        @include ultra-narrow-desktop {
            inset-block-start: 23px;
            inset-inline-end: 14px;
        }

        @include tablet {
            inset-block-start: 23px;
            inset-inline-end: 14px;
        }

        @include narrow-tablet {
            inset-block-start: 23px;
            inset-inline-end: 14px;
        }

        @include mobile {
            position: absolute;
            inset-inline-end: 10px;
        }

        > div:first-child {
            height: 20px !important;
        }
    }

    #conveythis-wrapper {
        position: relative !important;
    }

    .conveythis-widget-main {
        background-color: $black !important;
        border: none !important;
        width: 60px !important;
        border-radius: 0 !important;
    }

    .conveythis-widget-current-language-wrapper {
        .conveythis-widget-language {
            background-color: $black !important;
            height: 20px !important;

            &:hover {
                div:first-child {
                    color: var(--link-hover) !important;
                }

                .conveythis-language-arrow {
                    background-color: var(--link-hover) !important;
                }
            }

            div:first-child {
                color: $white !important;
                width: auto !important;
                font-size: 1.3rem !important;
                font-family: $font-muli !important;
                font-weight: 600;
                line-height: 20px !important;
                height: 20px !important;

                @include ultra-narrow-desktop {
                    font-size: 1.1rem !important;
                }

                @include tablet {
                    font-size: 1.1rem !important;
                }

                @include narrow-tablet {
                    font-size: 1.1rem !important;
                }
            }
        }
    }

    .conveythis-language-arrow {
        transform: rotate(90deg) !important;
        height: 20px !important;
        background-color: $white !important;
    }

    .conveythis-widget-languages {
        .conveythis-widget-language {
            background-color: #fff !important;
            padding-inline-start: 10px;

            div:first-child {
                line-height: 30px !important;
                height: 30px !important;
                width: auto !important;
                font-size: 1.3rem !important;
                font-family: $font-muli !important;
                
                &:hover {
                    color: var(--link-hover) !important;
                }
            }
        }

        > div:last-child {
            display: none !important;
        }
    }
}
