/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ReturnsCollapse {
    background-color: #fff;
    border: 1px solid #CECECE;
    width: 100%;
    max-width: 864px;
    margin-block-start: 35px;
    padding-inline-start: 30px;
    padding-inline-end: 25px;
    padding-block: 7px;

    @include mobile {
        padding-inline-start: 18px;
        padding-inline-end: 18px;
    }

    .text-return {
        padding-block: 50px;

        @include mobile {
            padding-block-start: 20px;
            padding-block-end: 30px;
        }

        p {
            font-size: 1.4rem;
            color: #1a1a1a;
            font-family: $font-base;
            padding-inline-end: 100px;

            @include tablet {
                padding-inline-end: 30px;
            }

            @include mobile {
                padding-inline-end: 0;
            }
        }
    }

    &-Button {
        cursor: pointer;

        button {
            width: 100%;
        }
    }

    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-size: 1.1rem;
            font-weight: 600;
            color: #222222;
            margin: 0;
            margin-block-end: 0;
        }
    }

    &-Icon {
        p {
            display: none;
        }

        &::before {
            content: '+'; 
            font-size: 3.3rem;
            font-weight: normal;
        }
        
        &_isCollapsed {
            &::before {
                content: '-';
            }
        }
    }
}
