/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

:root {
    --leaflet-marker-margin-inline-start: -10px;
    --leaflet-marker-margin-block-start: -40px;
    --leaflet-map-border: 1px solid #dbdbdb;
    --leaflet-map-margin-inline-mobile: 16px;
    --leaflet-map-height: 600px;
    --leaflet-map-height-mobile: 400px;
    --leaflet-popup-height-mobile: 400px;
    --leaflet-popup-margin-inline-start-mobile: 1.25rem;
    --leaflet-popup-padding: 12px;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-popup-content {
    margin: 0; // margin set by 'webpack:///./src/app/style/base/_link.scss'
}

.leaflet-container {
    z-index: 0;
    border: var(--leaflet-map-border);
    height: var(--leaflet-map-height);

    @include mobile {
        height: var(--leaflet-map-height-mobile);
    }
}

.leaflet-popup {
    margin-inline-start: var(--leaflet-popup-margin-inline-start-mobile);

    @include mobile {
        margin: 0;
    }

    &-tip-container {
        display: none;
    }

    &-content {
        height: 280px;
        overflow-y: auto;
        padding: var(--leaflet-popup-padding);

        &-wrapper {
            box-shadow: none;
            border-radius: 3px;
            width: 280px;
        }

        p {
            margin: 0px;
        }
    }
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    background: unset;
    border: unset;
    // vvv 1. the marker and it's shadows position is always off center, based on the image
    // being used, but we can override it for each project by overriding these variables
    // vvv 2. using flag `important` because the original leaflet css file has this tag too
    /* stylelint-disable-next-line declaration-no-important */
    margin-inline-start: var(--leaflet-marker-margin-inline-start) !important;
    /* stylelint-disable-next-line declaration-no-important */
    margin-block-start: var(--leaflet-marker-margin-block-start) !important;
}
