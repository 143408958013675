/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: $default-neutral-base-color;
    --breadcrumbs-color: $default-neutral-base-color;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    font-family: $font-base;
    font-weight: 600;

    @include desktop {
        margin-block-end: 60px;
        margin-block-start: 31px;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 60px;
        margin-block-start: 25px;
    }

    @include tablet {
        margin-block-end: 60px;
        margin-block-start: 25px;
    }

    @include narrow-tablet {
        margin-block-end: 60px;
        margin-block-start: 25px;
    }

    @include mobile {
        margin-block-start: 17px;
        display: block;
    }

    &-Black {
        @include ultra-narrow-desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include narrow-tablet {
            display: none;
        }
        
        .ContentWrapper {
            max-width: var(--content-wrapper-large);
            color: #fff !important;

            @include mobile {
                color: $black !important;
            }

            nav {
                position: fixed;
                inset-block-start: 135px;
                z-index: 100;

                @include mobile {
                    position: absolute;
                    inset-block-start: -25px;
                }
            }
        }

        .Breadcrumb {
            border-inline-end: 1px solid #fff;

            @include mobile {
                border-inline-end: 1px solid $black;
            }

            &:last-child {
                border-inline-end: none;
            }
        }
    }

    &-List {
        list-style: none;
        padding: 0;

        .ComingSoon {
            h2 {
                font-weight: 300;
                font-size: 4.2rem;
                margin: 0;
            }
        }
    }

    &.compare {
        @include mobile {
            display: none;
        }
    }
}
