/** extend sidea */
/* stylelint-disable declaration-no-important */

.banner {
    --banner-min-height: 357px;

    @include ultra-narrow-desktop {
        --banner-min-height: 270px;
    }

    @include narrow-tablet {
        --banner-min-height: 270px;
    }

    @include mobile {
        --banner-min-height: 375px;
    }

    position: relative;
    background-color: $default-neutral-base-color;
    color: $white;
    padding-inline-start: calc((100vw - var(--content-wrapper-large)) / 2);

    @include desktop {
        min-height: var(--banner-min-height) !important;        
    }

    @include tablet {
        min-height: unset !important;
    }

    @include mobile {
        min-height: 220px !important;
        width: 100vw;
        padding: 0;
        padding-inline-start: 0;
    }

    &.container {
        @include mobile {
            width: 100vw;
            padding-inline: 0;
        }
    }

    .pagebuilder-column-group {
        min-height: var(--banner-min-height);

        figure {
            height: 100%;
        }
    }

    h3 {
        &.special-big {
            font-weight: 800;
            font-size: 5.4rem;
            margin-block-start: 0;
            margin-block-end: 10px;

            @include desktop-wide-1600 {
                font-size: 4rem;
            }
    
            @include ultra-narrow-desktop {
                font-size: 4rem;
            }
    
            @include tablet {
                font-size: 4rem;
            }
    
            @include narrow-tablet {
                font-size: 4rem;
            }

            @include mobile {
                font-size: 5.4rem;
                line-height: 1;
                margin-block-start: 0;
                margin-block-end: 12px;
            }
        }
    }

    p {
        margin-block-end: 20px;

        @include mobile {
            margin-block-end: 13px;
            font-size: 1.4rem;
            line-height: 1.4;
        }
    }

    &.promo,
    &.progettazione {
        .pagebuilder-column-group {
            .pagebuilder-column {
                &:first-child {
                    @include desktop {
                        justify-content: center !important;
                        padding-inline-end: 7%;
                        max-width: 32.5%;
                    }

                    @include desktop-wide-1600 {
                        padding-inline-end: 6%;
                        max-width: 33.3333%;
                    }

                    @include desktop-wide-1440 {
                        padding-inline-end: 3%;
                    }

                    @include ultra-narrow-desktop {
                        padding-inline-end: 3%;
                        max-width: 33.3333%;
                    }

                    @include tablet {
                        padding-inline-end: 3%;
                        max-width: 33.3333%;
                    }

                    @include narrow-tablet {
                        padding-inline-end: 3%;
                        max-width: 33.3333%;
                    }

                    @include mobile {
                        max-width: 90vw;
                        position: absolute;
                        z-index: 10;
                        inset-inline-start: 5vw;
                        inset-block-start: calc((375px - 230px) / 2);
                        background-color: #222222;
                        width: 90vw;
                        height: 230px;
                        padding: 20px;                    
                    }
                }

                @include mobile {
                    width: 100% !important;
                }

                figure {
                    @include mobile {
                        height: 375px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        img {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    &.collect {
        padding-inline-start: 0;
        justify-content: center !important;

        .pagebuilder-column-group {
            display: flex;

            @include mobile {
                flex-direction: column;
            }

            &:first-child {
                @include desktop {
                    position: absolute;
                    z-index: 9;
                    width: 32.5%;
                    background-color: #222222;
                    color: #ffffff;
                    padding-inline-start: calc((100vw - var(--content-wrapper-large)) / 2);

                    .pagebuilder-column {
                        align-self: center !important;
                    }
                }

                @include desktop-wide-1600 {
                    max-width: 33.3333%;
                }

                @include ultra-narrow-desktop {
                    max-width: 33.3333%;
                }

                @include tablet {
                    max-width: 33.3333%;
                }

                @include narrow-tablet {
                    max-width: 33.3333%;
                }

                @include mobile {
                    max-width: 90vw;
                    position: absolute;
                    z-index: 10;
                    inset-inline-start: 5vw;
                    inset-block-start: calc((375px - 230px) / 2);
                    background-color: #222222;
                    width: 90vw;
                    height: 230px;
                    min-height: 230px;
                    padding: 24px 19px;
                    justify-content: flex-end;
                }
            }

            .image-column {
                figure {
                    @include mobile {
                        min-height: 375px;
                    }

                    img {
                        @include mobile {
                            width: auto;
                            height: 100%;

                            &.pagebuilder-mobile-only {
                                display: block;
                            }
                        }
                    }
                }
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }
            }
        }
    }

    h2 {
        font-weight: 800;
        font-size: 3rem;
        letter-spacing: 0.3rem;
        line-height: 1;
        margin-block-end: 15px;
        margin-block-start: -4px;

        @include desktop-wide-1600 {
            font-size: 2.8rem;
        }

        @include ultra-narrow-desktop {
            font-size: 2.4rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include narrow-tablet {
            font-size: 2.4rem;
        }

        @include mobile {
            font-size: 2.8rem;
        }
    }

    &.progettazione {
        .pagebuilder-column-group {
            @include mobile {
                flex-direction: column-reverse;
            }

            .pagebuilder-column {
                &:first-child {
                    @include mobile {
                        position: relative;
                        inset-block-start: auto;
                        inset-inline-start: 0;
                        width: 100%;
                        max-width: 100vw;
                        height: fit-content;
                        padding-block: 40px;
                        padding-inline: 19px;
                    }
                }

                figure {
                    @include mobile {
                        height: 187px;
                    }
                }
            }
        }

        h2 {
            @include mobile {
                font-size: 2.1rem;
            }
        }

        h3 {
            margin-block-end: 30px;

            @include mobile {
                margin-block-end: 15px;
            }
        }
    }
}

