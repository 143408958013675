/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.KeyValueTable {
    margin: 0;
    margin-block-end: 12px;
    min-width: 100%;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.5;
    color: #1A1A1A;

    table tbody tr:not(:last-child) {
        border-bottom: none;
    }

    @include narrow-desktop {
        font-size: 1.4rem;
    }

    @include ultra-narrow-desktop {
        font-size: 1.4rem;
    }

    @include narrow-tablet {
        font-size: 1.4rem;
    }

    @include mobile {
        margin-block-end: 14px;
    }

    &-Heading {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.4;
        background-color: #fff;
        border-radius: 0;
        color: #1A1A1A;
        padding: 0;
        padding-block-end: 20px;

        @include narrow-desktop {
            font-size: 1.6rem;
        }
    
        @include ultra-narrow-desktop {
            font-size: 1.6rem;
        }
    
        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.4rem;
        }
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Wrapper {
        @include mobile {
            overflow-x: auto;
        }
    }

    .RadioButtonIcon {
        display: none;
    }
}
