/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactForm {
    &-ColumnWrapper {
        margin-block-start: 80px;
        margin-block-end: 60px;
        padding-inline-start: 8.2%;
        display: flex;
        gap: 9.8%;
        
        @include ultra-narrow-desktop {
            padding-inline-start: 30px;
            gap: 7%;
        }

        @include tablet {
            padding-inline-start: 30px;
            gap: 7%;
        }

        @include narrow-tablet {
            padding-inline-start: 0;
            gap: 7%;
        }

        @include mobile {
            display: block;
            padding: 12px 0 0;
            margin-inline-start: 0;
            margin-block-end: 0;
            margin-block-start: 10px;
            padding-inline-start: 0;
        }
    }

    h2 {
        @include mobile {
            margin-block-end: 7px;
        }

        &.special {
            text-transform: none;
            margin-block: 0;
            letter-spacing: 0;

            @include mobile {
                font-size: 1.4rem;
                font-family: $font-base;
                font-weight: 400;
            }
        }
    }

    h3 {
        margin-block-start: 0;
        margin-block-end: 25px;

        @include mobile {
            font-size: 1.2rem;
            margin-block-end: 10px;
        }

        &.special {
            font-weight: 800;
            text-transform: none;
            margin-block-end: 60px;
            margin-block-start: 3px;
            letter-spacing: 0;

            @include narrow-desktop {
                margin-block-end: 35px;
            }
        
            @include ultra-narrow-desktop {
                margin-block-end: 35px;
            }
        
            @include tablet {
                margin-block-end: 35px;
            }
        
            @include mobile {
                font-size: 2.2rem;
                margin-block-end: 35px;
            }
        }
    }

    .Store-Name {
        margin-block-start: 25px;

        @include mobile {
            margin-block-start: 0;
        }

        h6 {
            font-family: $font-muli;
            font-size: 1.2rem;
            letter-spacing: 0.024rem;
            font-weight: 800;
            text-transform: uppercase;
            margin-block: 0;
        }
    }
    
    .p10, .p10 p {
        font-weight: 400;
        letter-spacing: 0;
        padding-block-start: 4px;
    }

    .contatti-head-container {
        a {
            font-weight: 400;
            padding-inline-start: 10px;
        }

        p {
            font-weight: 400;

            @include mobile {
                margin-block-end: 10px;
            }

            img {
                inset-block-start: 3px;
                margin-inline-end: 0;

                @include ultra-narrow-desktop {
                    width: 18px !important;
                }

                @include tablet {
                    width: 18px !important;
                }

                @include narrow-tablet {
                    width: 18px !important;
                }

                @include mobile {
                    width: 18px !important;
                }
            }
        }
    }

    &-RadioButtons {
        display: flex;
        max-width: 160px;

        @include mobile {
            margin-block-start: 15px;
            max-width: 200px;
        }
    }

    &-Columns {
        display: flex;
        gap: 30px;

        @include mobile {
            flex-direction: column;
            gap: 0;
        }
    }

    textarea {
        width: 100%;
        min-height: 188px;
        font-size: 1.2rem;
    }

    .FieldForm-Body {
        max-width: 880px;

        @include desktop-wide-1600 {
            max-width: 820px;
        }

        @include mobile {
            max-width: 100%;
            margin-block-start: 40px;
        }
    }

    .Field-Wrapper_type_textarea {
        margin-block-end: 30px;

        @include mobile {
            margin-block-end: 0;
        }
    }

    .Field-Wrapper {
        width: 100%;
        max-width: 420px;

        @include narrow-tablet {
            max-width: 100%;
        }
    }

    &-Fields {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    .Field [type='checkbox'] + .input-control {
        border: 1px solid #BABABA;
    }

    .Field.Field_type_checkbox {
        font-size: 1.2rem;
        color: #3A3939;
    }

    .bottom-form {
        margin-block-start: 25px;
        max-width: 450px;
    }

    .p-0 {
        padding: 0;
    }

    .input-control {
        width: 48px !important;

        @include mobile {
            width: 18px !important;
        }
    }

    .Field-CheckboxLabel {
        align-items: flex-start;
        font-size: 1rem;
        font-family: $font-base;

        @include mobile {
            font-size: 1.1rem;
            letter-spacing: 0.08rem;
            font-weight: 400;
        }
    }

    .Field-Wrapper .Field {
        margin-block-start: 0;
        margin-block-end: 20px;
    }

    label {
        display: none;
    }

    .ConsensoPrivacy {
        margin-block-start: 15px;
    }

    .Button.primary-black {
        min-width: 180px;
    }

    .Field-RadioLabel {
        font-size: 1.2rem;
        font-weight: 500;
        font-family: $font-base;
    }

    input[type=radio] + .input-control {
        border-radius: 0;
        border: 1px solid #BABABA;
    }

    &-Stores {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-inline: 8%;
        margin-block-end: 100px;

        @include ultra-narrow-desktop {
            padding-inline: 30px; 
        }

        @include tablet {
            padding-inline: 30px;
        }

        @include narrow-tablet {
            padding-inline: 0; 
        }

        @include mobile {
            flex-direction: column;
            padding-inline: 0;
            margin-block-end: 0;
        }
    }

    &-Store {
        display: flex;
        flex-direction: column;
        flex: 1 0 30%;
        margin-block-end: 30px;

        @include mobile {
            padding-block: 25px 30px;
            border-block-end: 1px solid #DEDEDE;
        }

        p {
            font-weight: 400;
            margin: 0;

            @include mobile {
                margin-block-end: 5px;
            }

            strong {
                text-transform: uppercase;
            }
        }

        div:first-of-type {
            margin-block-end: 60px;

            @include mobile {
                margin-block-end: 20px;
            }
        }

        div:nth-child(2) {
            p:first-child {
                &::before {
                    content: var(--small-phone);
                    margin-inline-end: 15px;
                }
            }

            p:nth-child(2) {
                &::before {
                    content: var(--small-message);
                    margin-inline-end: 15px;
                }
            }
        }

        .button_call {
            @include mobile {
                inset-block-start: 3px;
            }
        }
    }
}
