/** override sidea */

:root {
    --link-color: var(--imported_link_color, #{$black}) !important;
    --link-hover: var(--imported_linkHover_color, #{$default-primary-light-color});
    --default-hover-link-underline: 1px solid var(--link-hover);
    --link-underline: var(--imported_link_border_bottom, none);
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    border-block-end: var(--link-underline);
    color: $black;
    font-weight: 700;

    @include hoverable { 
        &:hover {
            color: var(--link-hover);
        }
    }
    
    &:active {
        color: var(--link-hover);
    }

    &.Link {
        &:hover,
        &:focus {
            border-block-end: var(--link-hover-underline);
            padding-block-end: 3px;
        }
    }
}

.link-txt {
    font-family: $font-muli;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    color: $default-neutral-base-color;

    &::before {
        width: 21px;
        height: 21px;
        display: inline-block;
        content: '';
        vertical-align: middle;
        -webkit-mask: var(--icon-arrow-circle) no-repeat 50% 50%;
        mask: var(--icon-arrow-circle) no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        margin-inline-end: 12px;
    }

    &:hover {
        color: $default-primary-base-color;

        &::before {
            background-color: $default-primary-base-color;
        }
    }
}
