/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        border: 1px solid $default-neutral-extralight-color;
        display: none;
        z-index: 110;
        width: 100%;
        inset-inline-end: 0;

        @include desktop-wide-1440 {
            width: 300px;
        }

        @include narrow-desktop {
            width: 300px;
        }

        @include tablet {
            width: 300px;
        }

        @include narrow-tablet {
            width: 300px;
        }

        .NoResults {
            padding-inline-start: 20px;
            color: #222;
        }

        @include desktop {
            padding: 20px 20px;
            padding-inline-end: 20px;
            position: absolute;
            inset-block-start: 36px;

            ul {
                max-height: 40vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                    margin-inline-end: 6px;
                }
                
                &::-webkit-scrollbar-track {
                    background-color: #c9c9c9;
                    border-radius: 3px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background-color: #656565;
                }

                .SearchItem {
                    padding: 10px 0 10px;
                    border-bottom: 1px solid #E3E3E3;

                    &-Wrapper {
                        grid-template-columns: 40px calc(100% - 60px);
                        align-items: center;
                    }

                    &-CustomAttribute {
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    h4 {
                        font-size: 1.1rem;
                        font-weight: 700;
                        line-height: 1.3;
                        margin-block-start: 2px;
                        margin-block-end: 0;
                    }

                    .Image {
                        border: 1px solid #E3E3E3;
                    }
                }
            }
        }
    }
}
