/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --navigation-tabs-height: 60px;
    --navigation-tabs-background: #{$black};
    --navigation-tabs-color: #{$white};
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;

    position: relative;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(0);
    }

    @include mobile {
        border-block-start: none;
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: block;
    }

    &-Nav {
        height: var(--navigation-tabs-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 12px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            padding: 0;
            max-width: 100%;
        }
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;

        &.Button-Menu,
        &.Button-Cart {
            width: 60px;
            min-width: 60px;
        }

        &.Button-Menu {
            svg {
                stroke: $white;
            }
        }

        &.Button-Cart {
            @include mobile {
                padding: 10px;
            }

            button {
                @include mobile {
                    background-color: $default-primary-base-color;
                }
            }

            .Header-MinicartButtonWrapper {
                &.filledCart {
                    svg {
                        @include mobile {
                            fill: $black;
                        }

                        path {
                            @include mobile {
                                fill: $black;
                            }
                        }
                    }
                }
                
                span.Header-MinicartItemCount {
                    @include mobile {
                        background-color: #fff;
                        border: 1px solid $black;
                        height: 23px;
                        min-width: 23px;    
                        border-radius: 50%;
                        font-family: $font-muli;
                        font-weight: 700;
                        color: $black;
                        text-align: center;
                        inset-inline-start: auto;
                        inset-inline-end: -6px;
                        inset-block-start: -6px;
                    }
                }
            }
        }

        &.Logo {
            width: calc(100% - 100px);

            @include mobile {
                justify-content: start;
            }
        }
    }

    &-Icon {
        font-weight: bold;
    }

    .Image {
        &.Logo {
            width: 185px;

            @include mobile {
                width: 115px;
            }
        }
    }
}
