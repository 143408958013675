/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Consent {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;

    &-Checkbox {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 500;
        margin-block-start: 0px;

        .label-text-required {
            display: inline-block;
            width: calc(100% - 30px);

            .label-text {
                display: inline-block;
                width: calc(100% - 30px);
            }

            span {
                display: inline-block !important;
                vertical-align: top;
            }
        }

        &_is_checked {
            input[type=checkbox] {
                cursor: default !important; /* stylelint-disable-line declaration-no-important */
            }
            
            .input-control {
                display: none !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        input[type=checkbox]:hover:not(:disabled) + .input-control {
            --box-color: #fff;

            border: 1px solid #454545;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
        }

        input[type=checkbox] + .input-control {
            border-radius: 12px !important; /* stylelint-disable-line */
        }

        input[type='checkbox'] + .input-control {
            --box-color: #fff;

            background-color: var(--box-color);
            border: 1px solid #000000;
            border-radius: var(--checkbox-border-radius);
            cursor: pointer;
            display: inline-block;
            height: 24px;
            position: relative;
            padding: 0;
            transition-duration: .25s;
            transition-property: background-color, border-color;
            transition-timing-function: ease-in;
            width: 24px;
            max-width: 24px;
            will-change: background-color, border-color;
        }

        input[type=checkbox] + .input-control::after {
            box-shadow: none;
            content: '';
            height: 14px;
            width: 14px;
            inset-block-start: 4px;
            left: 4px !important; /* stylelint-disable-line */
            transform: none;
            border-radius: 10px;
            transition: none;
            pointer-events: none;
            position: absolute;
            background: var(--checkmark-color);
            will-change: background-color, box-shadow;
        }

        input[type='checkbox']:hover:not(:disabled) + .input-control::after {
            --checkmark-color: #454545;
        }
    }
}
