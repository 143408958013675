/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InStoreRegistration {
    width: 100%;
    margin-block-start: 80px;
    padding-inline: 24.9%;

    @include desktop-wide-1440 {
        margin-block-start: 70px;
    }

    @include narrow-desktop {
        margin-block-start: 50px;
    }

    @include tablet {
        margin-block-start: 50px;
    }

    @include mobile {
        margin-block-start: 20px;
    }

    label {
        display: none;
    }

    .FieldForm {
        &-Fields {
            .col-md-6 {
                padding-inline: 0;
                max-width: 416px;

                p {
                    font-size: 1rem;
                }

                &:first-child {
                    margin-inline-end: 30px;
                }

                @include mobile {
                    padding-inline: 0;

                    &:first-child {
                        .Field-Wrapper_type_select {
                            margin-block-start: 40px;
                        }
                    }
                }
            }

            .third-block {
                display: flex;
                flex-direction: row;
                gap: 30px;
            }
        }
    }

    .Field {
        margin-block-start: 0;
    }

    .FieldSelect .ChevronIcon {
        inset-inline-end: 22px;
        inset-block-start: 24px;
    }

    .Field-Wrapper {
        margin-block-end: 25px;

        @include mobile {
            margin-block-end: 10px;
        }
    }

    input, select {
        height: 50px;
        margin: 0;
        font-family: $font-base;
        font-size: 1.2rem;

        @include mobile {
            font-size: 1.4rem;
        }
    }

    .Field-Wrapper.Field-Wrapper_type_text:has(input[name="guest_returns"]) {
        display: none;
    }

    input[name="guest_returns"] {
        display: none;
    }

    textarea {
        width: 100%;
        height: 400px;
        font-family: $font-base;
        font-size: 1.5rem;
        line-height: 1.4;

        @include mobile {
            font-size: 1.4rem;
            height: 200px;
        }
    }

    &-ActionBlock {
        margin-block-start: 35px;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .Field-CheckboxLabel {
        .label-text {
            font-size: 1.2rem;
        }
    }

    .Field_type_checkbox:has(input[name=different_address]) {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .Button {
        width: 100%;
        margin-block-start: 0px;
        background-color: #0D0D0D;
        color: #fff;
        border: 0;

        &:hover {
            background-color: #454545;
            border: 0;
            color: #fff;
        }

        @include tablet {
            margin: 0 auto;
            display: block;
        }
    }

    .ConsensoPrivacy {
        margin-block-start: 15px;
    }

    .Field_type_checkbox {
        .input-control {
            width: 40px;
            align-self: flex-start;
        }
    }
}
