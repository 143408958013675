/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* funziona, il compilatore non è aggiornato per :has() */
.Breadcrumbs {
    &:has(+ .Router-MainItems .MyAccount) {
      display: none;
    }
}

.AmastyMyReturnsPage {
    width: 100%;
    margin: auto;

    .HeadingNewRequest {
        margin: 0;
        margin-block-end: 20px;

        @include mobile {
            margin-block-end: 10px;
        }
    }

    .HeadingRequestsList {
        margin-block-start: 50px;

        @include ultra-narrow-desktop {
            margin-block-start: 40px;
        }

        @include tablet {
            margin-block-start: 40px;
        }

        @include narrow-tablet {
            margin-block-start: 30px;
        }

        @include mobile {
            margin-block-end: 15px;
        }
    }

    .amreturns-order-select {
        display: flex;
        align-items: baseline;

        @include mobile {
            flex-direction: column;
            gap: 15px;
            margin-block-end: 35px;
        }

        .amreturns-title-container {
            margin-inline-end: 30px;

            @include ultra-narrow-desktop {
                margin-inline-end: 20px;
            }
            
            @include tablet {
                margin-inline-end: 15px;
            }

            @include mobile {
                margin-inline-end: 5px;
            }

            .amreturns-select-title {
                font-weight: 600;
                line-height: 25px;
                color: #1a1a1a;
                font-size: 1.4rem;
                font-family: $font-muli;

                @include mobile {
                    font-size: 1.2rem;
                }
            }
        }

        .amreturns-orders-container {
            flex-grow: 1;
            display: flex;
            align-items: baseline;

            @include mobile {
                width: 100%;
                flex-direction: column;
                gap: 15px;
                align-items: flex-start;
            }

            .Field-Wrapper {
                flex-grow: 1;
                width: 100%;
                max-width: 487px;

                .Field {
                    margin-top: 0;
                }

                select {
                    height: 40px;
                    padding-block: 5px;

                    @include mobile {
                        height: 55px;
                    }
                }
            }

            .amreturn-btn {
                margin-inline-start: 30px;
                width: 100%;
                max-width: 225px;
                height: 40px;
                color: #fff;
                border: none;
                background-color: #0d0d0d;

                &:hover {
                    color: #fff;
                    border: none;
                    background-color: #454545;
                }

                @include ultra-narrow-desktop {
                    max-width: 150px;
                }

                @include tablet {
                    max-width: 150px;
                }

                @include mobile {
                    max-width: unset;
                }

                @include mobile {
                    margin-inline-start: 0;
                    max-width: 100px;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }

    .table-caption {
        display: none;
    }

    .amrma-table-container {
        margin-block-end: 75px;
        border: 1px solid #CECECE;
        padding: 36px 22px 45px 30px;
        background-color: #fff;

        @include mobile {
            border: none;
            margin-block-end: 25px;
            padding: 0;
        }
    }

    .table-wrapper {
        border-top: none;
        overflow-x: auto;
    }

    .amrma-table {
        border-collapse: collapse;
        border-spacing: 0;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-style: normal;
        line-height: 1.42857143;
        margin-bottom: 0;
        width: 100%;

        .amrma-status {
            padding: 5px 0px 1px;
            border-radius: 4px;

            @include mobile {
                padding-inline-start: 0;
            }
        }
    }

    table td, table th {
        min-width: unset;
    }

    table tbody tr {
        border-bottom: 1px solid #DEDEDE !important;

        @include mobile {
            &.amrma-tbody {
                @include mobile {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            &.amrma-row {
                border: 1px solid #CECECE !important;
                padding: 20px 18px;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .amrma-table thead {
        color: #1A1A1A;
        font-size: 1.5rem;
        border-bottom: 1px solid #DEDEDE;
        font-family: $font-base;
    }

    .amrma-table thead tr th,
    .amrma-table .amrma-tbody tr td {
        background-color: #fff;
        font-size: 1.5rem;
        font-family: $font-base;
        color: #1A1A1A;
        text-align: start;
        padding-inline: 0;

        @include desktop-wide-1440 {
            font-size: 1.5rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.4rem;
        }

        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.4rem;
            text-align: start;
            padding-block: 3px;
        }
    }
    
    .amrma-table thead tr th {
        font-family: $font-base;
        font-weight: bold;
        text-align: start;
        padding-inline-start: 15px;

        @include mobile {
            display: none;
        }
    }

    .amrma-table .amrma-tbody tr td {
        font-family: $font-base;
        vertical-align: middle;
        padding-inline-start: 15px;

        @include tablet {
            font-size: 1.3rem;
        }

        @include mobile {
            font-weight: 400;
            font-size: 1.4rem;
            width: 100%;
            padding-inline-start: 0;
        }

        span.orderLabel {
            display: none;
            font-weight: 600;

            @include mobile {
                display: inline-block;
                min-width: 30%;
            }
        }
    }

    .col.actions {
        .action.view {
            font-size: 1.2rem !important;
            text-decoration: underline;
            font-weight: normal !important;
            font-family: $font-base;
            color: #1A1A1A;

            &:hover {
                color: $default-primary-light-color;
            }    
        }

        @include mobile {
            font-size: 1.4rem !important;
            text-align: end !important;
        }
    }

    .col.id, .col.total {
        width: 18%;
    }

    .col.status {
        width: 20%;
    }

    .returns-history > .amrma-table .amrma-tbody > .amrma-row .amrma-image {
        width: 50px;
        height: 60px;
    }

    .message.info {
        margin: 10px 0 20px;
        padding: 12px 20px;
        display: block;
        font-size: 1.6rem;
        background: #fdf0d5;
        color: #6f4400;
        position: relative;
    }
}

