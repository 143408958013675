/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.Breadcrumb {
    $crumb-padding: 10px;

    display: inline-block;
    vertical-align: top;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    padding-block-start: 0;
    border-right: 1px solid $default-neutral-base-color;
    padding-inline-end: $crumb-padding;
    margin-inline-end: $crumb-padding;

    @include mobile {
        font-size: 1rem;
        line-height: 0.8;
        padding-block-start: 0;
        padding-inline-end: 6px;
        margin-inline-end: 6px;
    }

    h1 {
        font-weight: 300;
        line-height: 60px;

        @include ultra-narrow-desktop {
            font-size: 2.8rem;
            line-height: 50px;
        }

        @include tablet {
            font-size: 2.7rem;
            line-height: 50px;
        }

        @include mobile {
            font-size: 2rem;
            line-height: 20px;
        }
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg) brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 10px;
        height: 8px;
        inset-block-end: 0;

        @include mobile {
            margin-inline-start: 5px;
        }
    }

    &:last-child {
        margin-block-end: 0;
        border-right: none;
        font-weight: 700;

        a {
            color: --breadcrumbs-active-color;
            font-weight: 800;
        }

        .ChevronIcon {
            display: none;
        }

        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-size: 1rem;
        font-weight: 500;

        a {
            color: var(--breadcrumbs-color)
        }

        &:hover {
            color: var(--breadcrumbs-active-color);
        }
    }
}
