/* stylelint-disable declaration-no-important */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

#fidelity-card {
    @include mobile {
        border-bottom: 1px solid #DEDEDE;
    }

    .CmsPage-Content {
        > div:nth-child(3) {
            @include mobile {
                &::after {
                    content: var(--fidelity-people-mobile);
                    display: block;
                    width: 100%;
                    height: auto;
                    margin-inline-start: -19px;
                    margin-block-end: 40px;
                }
            }
        }
    }

    .banner-collect-page {
        @include mobile {
            width: 100vw;
            margin-inline-start: -19px;
        }

        .pagebuilder-column-group {
            @include mobile {
                flex-direction: column;

                .pagebuilder-column {
                    width: 100%;
                    min-height: 190px;

                    figure {
                        display: flex;
                    }
                }
            }
        }
    }

    h2.title-click-type {
        margin-block-end: 15px !important;
    }
}

.background-second-column-fidelity {
    background-image: var(--fidelity-image);
    margin-inline-start: 18px;

    @include ultra-narrow-desktop {
        margin-inline-start: 8px; 
    }

    @include tablet {
        margin-inline-start: 8px;
    }

    @include mobile {
        margin-inline-start: 0;
        background-position: top center !important;
    }
}

.h4-page-fidelity {
    @include narrow-desktop {
        margin-block-start: -20px;
    }

    @include tablet {
        margin-block-start: -20px;
    }

    p {
        font-weight: 600;
        font-size: 2.1rem;
        margin-block-end: 0;
        font-family: 'Oswald';

        @include narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.6rem;
        }

        @include mobile {
            font-size: 1.3rem;
            font-weight: 500;
        }
    }
} 

.fidelity-banner-text {
    margin-block-start: 20%;

    @include mobile {
        margin-block-start: 80px;
        margin-block-end: 25px !important;
    }

    p {
        margin-block-end: 0;
        font-size: 2.4rem;
        line-height: 1.25;
        color: #FFFFFF;
        font-family: 'Oswald';

        @include desktop-wide-1600 {
            font-size: 2.1rem;
        }

        @include narrow-desktop {
            font-size: 1.8rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.6rem; 
        }

        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.2rem;
        }
    }
}

.button-banner {
    display: flex;
    justify-content: center;
    width: 100%;

    @include desktop-wide-1600 {
        margin-block-start: -15px;
    }

    @include mobile {
        margin-block-start: 0;
    }

    .button-banner-text {
        width: 100%;
        max-width: 327px;

        @include mobile {
            max-width: 198px;
            position: absolute;
            inset-block-end: -20px;
        }

        .pagebuilder-button-primary {
            width: 100%;
            font-size: 2.4rem;
            border: none;
            color: #3A3939;
            background: #FFFFFF;
            border-radius: 20px;
            height: 50px;
            align-self: center;
            box-shadow: 8px 9px #DEDEDE;

            @include ultra-narrow-desktop {
                font-size: 1.8rem;
                height: 40px;
                border-radius: 15px;
                min-width: 250px;
                max-width: 250px;
            }

            @include tablet {
                font-size: 1.6rem;
                height: 40px;
                border-radius: 15px;
                min-width: 250px;
                max-width: 250px;
            }

            @include mobile {
                font-size: 1.6rem;
                height: 40px;
                border-radius: 20px;
                min-width: 198px;
                max-width: 198px;
            }

            &:hover {
                border: 1px solid #3A3939;
            }

            span {
                font-family: 'Oswald';
                font-weight: normal;
            }
        }
    }
}

.area-riservata, .checkrapido, .promofid, .punti {
    position: absolute;
    z-index: 99999;
    inset-inline-start: 15px;
    inset-block-end: 105px;

    @include mobile {
        position: relative;
        inset-inline-start: 0;
        inset-block-end: 0;
        z-index: 1;
        text-align: center;
    }

    p {
        color: #FFFFFF;
        font-size: 4.2rem;
        font-family: 'Oswald';
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;

        @include desktop-wide-1600 {
            font-size: 3.8rem;
        }

        @include narrow-desktop {
            font-size: 3.2rem;
        }

        @include tablet {
            font-size: 2.4rem;
        }

        @include mobile {
            font-size: 2rem;
            color: $default-primary-base-color;
            text-align: center;
        }
    }
}

.checkrapido {
    width: 50%;

    @include desktop-wide-1440 {
        width: 60%; 
    }

    @include mobile {
        width: 100%;
    }
}

.area-riservata {
    @include narrow-desktop {
        width: 95%;
    }

    @include ultra-narrow-desktop {
        width: 93%;
    }

    @include tablet {
        width: 95%;
    }

    @include mobile {
        width: 100%;

        p {
            padding-inline: 10%;
        }
    }
}

h3 {
    &.mod {
        font-size: 4.2rem;
        font-weight: bold;
        line-height: 1.4;
        color: #3A3939;
        text-align: center;
        margin-block-start: 0;

        @include narrow-desktop {
            font-size: 3.2rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include mobile {
            font-size: 3rem !important;
            line-height: 1.3;
        }
    } 
}

.container-fc {
    width: 46%;
    margin: auto;

    &.mod {
        width: 37%;

        @include desktop-wide-1600 {
            width: 51%;
        }

        @include desktop-wide-1440 {
            width: 53%;
        }

        @include narrow-desktop {
            width: 70%;
        }

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 100%;

            &.mb-7 {
                margin-block-end: 30px !important;
            }
        }
    }

    &.mb-20 {
        @include mobile {
            margin-block-end: 40px !important;
        }
    }

    .second-figure-column {
        margin-inline-start: 60px;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    .pagebuilder-column-group {
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            margin-block-end: 10px;
        }

        .pagebuilder-column {
            @include mobile {
                width: 100% !important;
                max-width: 270px;
                min-height: 220px;
                border: 1px solid #DEDEDE;
                padding: 20px;
                margin: 0 auto;

                figure {
                    display: none;
                }

                &.mb-4 {
                    margin-block-end: 0 !important;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 25px;
                    height: 35px;
                    background-image: var(--icona_uomo_corsa);
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    inset-block-end: 20px;
                    inset-inline-start: 45%;
                }
            }
        }
    }

    @include desktop-wide-1600 {
        width: 60%;
    }

    @include ultra-narrow-desktop {
        width: 70%;
    }

    @include tablet {
        width: 70%;
    }

    @include mobile {
        width: 100%;

        &.mb-10 {
            margin-block-end: 0px !important;
        }
    }

    .p16 {
        width: calc(100% - 35px);
        margin: 0 auto;
        min-height: 75px;

        @include mobile {
            width: 100%;
        }

        p {
            line-height: 1.55 !important;
            margin-block-end: 0;

            @include desktop-wide-1440 {
                font-size: 1.4rem;
            }

            @include narrow-desktop {
                font-size: 1.4rem;
            }

            @include tablet {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.3rem;
                line-height: 1.4 !important;
                text-align: center;
            }
        }
    }

    &.list-steps {
        &.mb-7 {
            @include ultra-narrow-desktop {
                margin-block-end: 40px !important;
            }

            @include tablet {
                margin-block-end: 40px !important;
            }
        }

        .p16 {
            @include desktop {
                width: 100%;
            }
        }

        .pagebuilder-column-group {
            @include narrow-tablet {
                flex-direction: column;
                text-align: center;
                width: 100%;
            }

            .number-al {
                @include narrow-tablet {
                    width: 100% !important;
                    align-items: center;
                }

                .p16 {
                    @include narrow-tablet {
                        width: 100%;
                        max-width: 500px;
                    }

                    p {
                        @include narrow-tablet {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
