/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends style of component */

:root {
    --newsletter-subscription-button-background: var(--link-color);
}

.ContentWrapper {
    &.Newsletter-NewsletterContent {
        color: $default-neutral-base-color;
        margin-block: 110px 120px;
        max-width: var(--content-wrapper-width);
        text-align: center;

        @include mobile {
            text-align: start;
        }

        h2 {
            margin-block-end: 35px;
            letter-spacing: 2px;

            @include mobile {
                width: 280px;
                line-height: 1.1;
                margin-block-start: 0;
            }

            span {
                @include mobile {
                    display: block;
                }
            }
        }

        .NewsletterSubscription {
            max-width: 928px;
            margin: 0 auto;
            margin-block-start: 75px;

            @include ultra-narrow-desktop {
                width: 65vw;
                margin-block-start: 50px;
            }

            @include tablet {
                width: 65vw;
                margin-block-start: 50px;
            }

            @include narrow-tablet {
                width: 70vw;
                margin-block-start: 45px;
            }

            @include mobile {
                margin-block-start: 16px;
            }

            h4 {
                margin-block-end: 8px;

                @include mobile {
                    text-align: center;
                }
            }

            .FieldForm {
                &-Fieldset {
                    @include mobile {
                        display: flex;
                        flex-direction: column;
                    }

                    .FieldGroup_isValid {
                        padding-inline-start: 0;
                        border-left: none;
                    }

                    .Field-Wrapper {
                        &_type_email {
                            width: 64%;
                            display: inline-block;
                            vertical-align: middle;

                            @include mobile {
                                width: 100%;
                                display: block;
                                order: 1;
                            }
                        }

                        &-Button {
                            width: 36%;
                            display: inline-block;
                            vertical-align: top;
                            background-color: $black;
                            padding-block: 15px;

                            @include ultra-narrow-desktop {
                                padding-block: 10px;
                            }
                    
                            @include tablet {
                                padding-block: 10px;
                            }
                    
                            @include narrow-tablet {
                                padding-block: 10px;
                            }

                            @include mobile {
                                width: 100%;
                                display: block;
                                background-color: transparent;
                                padding-block: 0;
                                order: 3;
                            }
                        }
                    }

                    .Field {
                        width: 100%;
                        margin-block-start: 0;
        
                        input {
                            &[type=email] {
                                background-color: #ffffff;
                                border: 1px solid #D8D8D8;
                                height: 70px;
                                padding: 30px 19px 13px;
                                font-size: 1.4rem;
                                font-weight: 500;
                                caret-color: #222;

                                @include ultra-narrow-desktop {
                                    height: 60px;
                                }
                        
                                @include tablet {
                                    height: 60px;
                                }
                        
                                @include narrow-tablet {
                                    height: 60px;
                                }

                                @include mobile {
                                    padding: 20px 20px 0;
                                    height: 60px;
                                }

                                &::placeholder {
                                    color: $default-neutral-dark-color;
                                    opacity: 1;
                                    font-weight: 500;
                                }
                            }

                            &[type=checkbox] {
                                padding: 0;
                                border: 1px solid #3A3939;
                                border-radius: 0;

                                + .input-control {
                                    border-radius: 0px !important;
                                    max-width: var(--checkbox-width);
                                    width: 24px;
                                    height: 18px;
                                    margin-inline-end: 0;

                                    &::after {
                                        box-shadow: none;
                                        content: '';
                                        height: 8px;
                                        width: 8px;
                                        inset-block-start: 4px;
                                        inset-inline-start: 4px;
                                        transform: none;
                                        border-radius: 0px;
                                        transition: none;
                                    }
                                }

                                &:hover:not(:disabled) + .input-control {
                                    --box-color: #fff;

                                    border: 1px solid #3A3939;
                                    margin-inline-end: 0;
                                }
                            }
                        }

                        &.Field_type_checkbox {
                            margin-block-start: 35px;
                            font-size: 1rem;
                            letter-spacing: 1px;
                            font-weight: 500;

                            @include mobile {
                                margin-block-start: 22px;
                                font-weight: 400;
                                letter-spacing: 0.08rem;
                            }

                            .Field-CheckboxLabel,
                            .Field-RadioLabel {
                                width: 100%;
                                align-items: center;

                                .label-text {
                                    display: inline-block;
                                    text-align: start;
                                }

                                @include mobile {
                                    text-align: start;
                                    align-items: flex-start;
                                    gap: 18px;
                                }

                                &:not([class*='_isDisabled']):hover {
                                    color: $black;
                                }
                            }
                        }
                    }

                    .Consent {
                        @include mobile {
                            order: 2;
                        }

                        &-Checkbox {
                            .label-text-required {
                                @include mobile {
                                    width: 100%;

                                    .label-text {
                                        width: calc(100% - 20px);
                                    }

                                    span {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
        
                &-Fields {
                    margin-block-end: 15px;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        
            .Button {
                margin-block-start: 0;

                @include mobile {
                    background-color: $default-neutral-base-color;
                    border-color: $default-neutral-base-color;
                    color: $white;
                    display: block;
                    margin-block-start: 25px;
                }
            }
        }
    }
}



