/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Style component Sidea */

.SizeGuide-Popup {
    .Popup {
        &-Content {
            background-color: #fff;
            border-radius: 0;
            min-width: 50%;
            overflow-x: hidden;
            max-height: unset;
            overflow-y: auto;
        }
    }
}
